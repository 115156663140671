import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import vartanaLogo from '../../../assets/vartana_logo.svg';
import partnershipWithVartana from '../../../assets/partnership_with_vartana.svg';
import CloseConfirmation from '../../../components/Modals/CloseConfirmation';
import { CloseConfirmationModalContext } from '../../../context/ConfirmationModalContext';
import AutoLoad from '../../../components/AutoLoad';
import { isRenderedFromHubspot } from '../../../utils/helpers';

// the v2 pages show updated styles like the new footer
const v2Pages = ['more-info', 'share-link', '/more-details'];
const HORIZONTAL_PADDING_PAGES = ['forms/order'];

function Forms({ isLoading, seller }) {
  const { search, pathname } = useLocation();
  const useQueryParams = new URLSearchParams(search);
  const referrer = useQueryParams.get('referrer');
  const application = useQueryParams.get('application');
  const isHubspot = application === 'hubspot';

  const logo = useMemo(() => {
    if (referrer === 'partner') {
      return {
        source: seller.logoUrl,
        altText: seller.name,
      };
    }
    return {
      source: vartanaLogo,
      altText: 'vartana',
    };
  }, [seller, referrer]);

  const [showClosePopup, setshowClosePopup] = useState(false);
  const [needCloseConfirmation, setNeedCloseConfirmation] = useState(false);

  const closeTab = useCallback(() => {
    if (needCloseConfirmation) {
      setshowClosePopup(true);
    } else {
      window.close();
    }
  }, [needCloseConfirmation]);

  const closeConfirmationModalContext = useMemo(
    () => ({
      // needs to be constant across rerenders
      setNeedCloseConfirmation,
      closeTab,
    }),
    [setNeedCloseConfirmation, closeTab]
  );

  const commonFooterStyles = useMemo(() => {
    return isRenderedFromHubspot() ? 'hubspot-expanded-footer static' : '';
  }, []);

  const styles = useMemo(() => {
    const showPaddingX = HORIZONTAL_PADDING_PAGES.some(
      (page) => !pathname.includes(page)
    );
    const isV2 =
      pathname.endsWith('/v2') || v2Pages.some((page) => pathname.includes(page));
    if (isHubspot)
      return {
        tab: 'flex flex-row justify-center items-center',
        outer: 'bg-white m-10 p-2 w-4/5 max-w-5xl',
        inner: '',
      };
    if (isV2)
      return {
        tab: 'flex justify-center items-center',
        outer: 'w-full lg:max-card-width',
        inner: 'content-container',
        footer: twMerge(
          commonFooterStyles,
          'expanded-app-footer flex flex-row-reverse border-t border-vartana-gray-30'
        ),
      };
    return {
      tab: 'flex justify-center items-center',
      outer: 'w-full lg:max-card-width',
      inner: `${
        showPaddingX ? 'px-24' : ''
      } pt-6 bg-vartana-steel-20 min-h-[100vh] flex flex-col justify-between`,
      footer: twMerge(
        commonFooterStyles,
        'flex flex-row-reverse mt-6 border-t border-vartana-gray-30 px-24 py-6'
      ),
    };
  }, [isHubspot, pathname, commonFooterStyles]);

  const poweredBy = useMemo(() => {
    if (isHubspot) return null;
    return (
      <div className={styles.footer}>
        <img alt="powered-by-vartana" src={partnershipWithVartana} />
      </div>
    );
  }, [isHubspot, styles]);

  return (
    <>
      <CloseConfirmation
        open={showClosePopup}
        onClose={() => setshowClosePopup(false)}
        onConfirm={() => window.close()}
      />
      <CloseConfirmationModalContext.Provider value={closeConfirmationModalContext}>
        <div className={styles.tab}>
          <div className={styles.outer}>
            <div className={styles.inner}>
              {isHubspot && (
                <img alt={logo.altText} src={logo.source} className="mb-9 h-7" />
              )}
              <AutoLoad
                loading={isLoading}
                containerClassName="absolute top-1/2 left-1/2"
              >
                <Outlet />
                {poweredBy}
              </AutoLoad>
            </div>
          </div>
        </div>
      </CloseConfirmationModalContext.Provider>
    </>
  );
}

Forms.propTypes = {
  seller: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};

Forms.defaultProps = {
  seller: {
    name: '',
    logoUrl: '',
  },
};

export default Forms;
