// This file contains constants for progen related files in the vendor app

// Define the app names constant
export const APP_NAMES = {
  HUBSPOT: 'hubspot', // App name for HubSpot
};

export const TAB_NAMES_MAP = {
  signature: 'sendForSignature',
  installment: 'offerInstallment',
  defer: 'deferPayment',
};

export const TAB_NAME_LABELS_MAP = {
  sendForSignature: 'Send for signature',
  offerInstallment: 'Offer installment plan',
  deferPayment: 'Defer payment',
};

// add more here as per usage
export const APPRAISAL_STATES = {
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
  NEED_INFORMATION: 'NEED INFORMATION',
};
