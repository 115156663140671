import { ReactElement } from 'react';
import { AlertBox, Typography } from '@vartanainc/design-system';

import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { sessionVar } from '../../graphql/cache';
import { labelValueField } from '../../constants/common.constants';

interface PaymentOptionsType {
  approvedAmount: string;
  availableAmount: string;
  contractLength: string;
  billingFrequency: string;
  netTerms: string;
  helpText?: string;
}

interface RequestSummaryType {
  totalContractValue: string;
  additionalAmount: string;
  paymentOption: string;
  contractLength: string;
  billingFrequency: string;
  netTerms: string;
}

interface ModifyTermsSummaryProps {
  paymentOptions?: PaymentOptionsType;
  requestSummary?: RequestSummaryType;
  isInstallment?: boolean;
  currency: string;
}

export function ModifyTermsSummary({
  paymentOptions,
  requestSummary,
  isInstallment,
  currency,
}: ModifyTermsSummaryProps): ReactElement {
  const sessionData = useReactiveVar(sessionVar);
  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );

  return (
    <div className="summary-container flex flex-col flex-wrap divide-y gap-y-6">
      <div>
        <Typography variant="heading16" color="color-black-100" bold>
          Current payment options
        </Typography>
        {paymentOptions?.helpText ? (
          <div className="mt-4">
            <AlertBox
              variant="alert-box"
              backgroundColor="info"
              content={paymentOptions?.helpText}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <div className="w-[8.125rem]">
              {labelValueField(
                'Available amount',
                paymentOptions?.availableAmount || '...',
                null,
                !paymentOptions?.availableAmount
              )}
            </div>
            {isInstallment && (
              <>
                <div className="w-[9.375rem]">
                  {labelValueField(
                    'Contract length',
                    paymentOptions?.contractLength || '...',
                    null,
                    !paymentOptions?.contractLength
                  )}
                </div>
                <div className="w-[8.125rem]">
                  {labelValueField(
                    'Billing frequency',
                    paymentOptions?.billingFrequency || '...',
                    null,
                    !paymentOptions?.billingFrequency
                  )}
                </div>
              </>
            )}
            <div className="w-[9.375rem]">
              {labelValueField(
                'Net terms',
                paymentOptions?.netTerms || '...',
                null,
                !paymentOptions?.netTerms
              )}
            </div>
            {multipleCountriesEnabled && (
              <div className="w-[8.125rem]">{labelValueField('Currency', currency)}</div>
            )}
          </div>
        )}
      </div>
      <div className="pt-6">
        <Typography variant="heading16" color="color-black-100" bold>
          Request summary
        </Typography>
        <div className="flex flex-col">
          <div className="mt-4 flex flex-row gap-4">
            <div className="w-[9.375rem]">
              {labelValueField(
                'Total contract value',
                requestSummary?.totalContractValue || '...',
                null,
                !requestSummary?.totalContractValue
              )}
            </div>
            <div className="w-[8.125rem]">
              {labelValueField(
                'Additional amount',
                requestSummary?.additionalAmount || '...',
                null,
                !requestSummary?.additionalAmount
              )}
            </div>
          </div>
          {isInstallment && (
            <div className="mt-4 flex flex-row gap-4">
              <div className="w-[9.375rem]">
                {labelValueField(
                  'Payment Option',
                  requestSummary?.paymentOption || '...',
                  null,
                  !requestSummary?.paymentOption
                )}
              </div>
              <div className="w-[8.125rem]">
                {labelValueField(
                  'Contract length',
                  requestSummary?.contractLength || '...',
                  null,
                  !requestSummary?.contractLength
                )}
              </div>
            </div>
          )}
          <div className="mt-4 flex flex-row gap-4">
            {isInstallment && (
              <div className="w-[9.375rem]">
                {labelValueField(
                  'Billing frequency',
                  requestSummary?.billingFrequency || '...',
                  null,
                  !requestSummary?.billingFrequency
                )}
              </div>
            )}
            <div className="w-[8.125rem]">
              {labelValueField(
                'Net terms',
                requestSummary?.netTerms || '...',
                null,
                !requestSummary?.netTerms
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
