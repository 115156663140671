import { ReactElement, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AlertBox, Typography } from '@vartanainc/design-system';
import * as yup from 'yup';
import { get, some } from 'lodash';
import { useReactiveVar } from '@apollo/client';

import PatternInput from '../../../designSystem/PatternInput/PatternInput';
import AddressInputField from '../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import {
  commonRegex,
  EIN_PLACEHOLDER,
  zipSchema,
} from '../../../constants/common.constants';
import { useSetAddressFields } from '../../../utils/hooks';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import { ReactComponent as TooltipIcon } from '../../../assets/tooltip.svg';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import MultiCountryZipInput from '../../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import {
  getCountryOptions,
  getStateOptions,
  handleCountrySelect,
} from '../../../utils/helpers';
import { sessionVar } from '../../../graphql/cache';

interface BusinessDetailsProps {
  businessName: string;
  ein: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface BusinessDetailsFormProps {
  businessDetailProps?: BusinessDetailsProps | null;
  handleSubmit: () => void;
  formRef: React.RefObject<unknown>;
  setSubmissionDisabled: (isDisabled: boolean) => void;
  alertText?: string;
  hideCompanyTaxId?: boolean;
}

const EIN_FIELD_FORMAT = '##-#######';

export function BusinessDetailsForm({
  businessDetailProps,
  handleSubmit,
  formRef,
  setSubmissionDisabled,
  alertText,
  hideCompanyTaxId,
}: BusinessDetailsFormProps): ReactElement {
  const session = useReactiveVar(sessionVar);
  const showCountryField = useMemo(() => {
    return get(session, 'session.user.company.product.multipleCountriesEnabled', false);
  }, [session]);
  const supportedCountries = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCountries', []);
  }, [session]);

  const preExistingEIN = businessDetailProps?.ein;

  const [resetEINFlag, setResetEINFlag] = useState(true);
  const formSchema = yup.object().shape({
    businessName: yup
      .string()
      .required('Registered company name is required')
      .test('validity check', 'Please enter a valid registered company name', (value) => {
        if (value) return commonRegex.atLeastOneAlphabet.test(value);
        return false;
      }),
    // set the format for EIN: where ein is a digit or skip validation if the ein field is disabled
    ein: yup
      .string()
      .test('validity check', 'Please enter a valid company tax id', (value) => {
        // if no ein is entered return true
        if (!value || value === EIN_PLACEHOLDER) return true;
        if (preExistingEIN && preExistingEIN === value) return true;
        // this regex validates ein in format ##-#######
        if (value) return commonRegex.einFormat.test(value);
        return false;
      }),
    // TODO: discuss why we had a check here to only allow alphanumeric characters and spaces
    street: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zip: zipSchema,
    country: yup.string().required('Country is required'),
  });

  const fallBackCountry = useMemo(() => {
    if (!showCountryField) {
      return supportedCountries[0];
    }
    const defaultCountry = get(businessDetailProps, 'country', '');
    return defaultCountry;
  }, [showCountryField, supportedCountries, businessDetailProps]);

  const formikBag = useFormik({
    initialValues: { ...businessDetailProps, country: fallBackCountry },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue, validateField } = formikBag;

  useImperativeHandle(formRef, () => formikBag);

  useEffect(() => {
    const isDisabled =
      some(formikBag.values, (value, key) => {
        if (!value && key !== 'ein') return true;
        return false;
      }) ||
      formikBag.isSubmitting ||
      !formikBag.isValid;

    setSubmissionDisabled(isDisabled);
  }, [
    formikBag.values,
    formikBag.isSubmitting,
    formikBag.isValid,
    setSubmissionDisabled,
  ]);

  const handleEinKeyUp = (e): void => {
    // If the user's pressed key is numeric we would register it as the first digit of the EIN else we would keep ein as empty
    if (resetEINFlag && preExistingEIN) {
      if (commonRegex.isNumber.test(e.key)) setFieldValue('ein', e.key);
      else setFieldValue('ein', '');
      setResetEINFlag(false);
    }
  };

  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    showCountryField
  );

  return (
    <FormikProvider value={formikBag}>
      <Typography variant="heading18" bold color="color-black-100">
        Business details
      </Typography>
      {alertText && (
        <div className="mt-4 max-w-[36.5rem]">
          <AlertBox
            variant="alert-box"
            backgroundColor="announcement"
            icon={<SvgIcon name="info" fill="color-blue-120" />}
            content={alertText}
          />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <TextInputField
                {...{
                  name: 'businessName',
                  label: 'Registered company name',
                  fullWidth: 'true',
                }}
                ref={null}
              />

              {!hideCompanyTaxId && (
                <div className={`w-full ${formikBag.values.ein ? 'ein-form-field' : ''}`}>
                  <PatternInput
                    id="ein"
                    name="ein"
                    label="Company tax ID (optional)"
                    format={
                      formikBag.values.ein === preExistingEIN && preExistingEIN
                        ? preExistingEIN
                        : EIN_FIELD_FORMAT
                    }
                    placeholder
                    value={formikBag.values.ein}
                    mask="_"
                    tooltipIcon={<TooltipIcon />}
                    tooltipElement={(
                      <Typography variant="paragraph14" color="#ffffff">
                        Company tax ID is a nine-digit
                        <br />
                        number the IRS uses to identify a<br />
                        business&apos;s tax records.
                      </Typography>
                    )}
                    disableFullStoryRecording
                    onKeyDown={(e) => {
                      // Reset EIN if user starts typing for the first time with pre-filled EIN field
                      if (resetEINFlag && preExistingEIN) setFieldValue('ein', '');
                    }}
                    onKeyUp={(e) => {
                      handleEinKeyUp(e);
                    }}
                  />
                </div>
              )}
            </div>

            <AddressInputField
              id="address"
              name="street"
              label="Primary address"
              countries={supportedCountries}
              afterPlaceSelect={(tempSelectedPlace) => {
                setSelectedPlace(tempSelectedPlace);
              }}
            />

            <div className="flex flex-row gap-4">
              <div className="w-1/2">
                <TextInputField
                  {...{
                    name: 'city',
                    label: 'City',
                    fullWidth: 'true',
                  }}
                  ref={null}
                />
              </div>
              <div className="w-1/2 flex flex-row gap-4 state-dropdown-container">
                <div className="w-[10.625rem]">
                  <CustomDropdown
                    name="state"
                    label="State"
                    placeholder="Select"
                    options={getStateOptions(supportedCountries) || []}
                    onChange={(e) => {
                      setFieldValue('state', e.value);
                    }}
                  />
                </div>
                <div className="w-[6.125rem]">
                  <MultiCountryZipInput
                    {...{
                      name: 'zip',
                      label: 'Zip',
                      placeholder: 'XXXXX',
                      fullWidth: 'true',
                    }}
                    ref={null}
                  />
                </div>
              </div>
            </div>
            {showCountryField && (
              <CustomDropdown
                id="country"
                name="country"
                label="Country"
                options={getCountryOptions(supportedCountries) || []}
                placeholder="Select"
                onChange={(e) => handleCountrySelect(formikBag, e.value)}
              />
            )}
          </div>
        </fieldset>
      </form>
    </FormikProvider>
  );
}
