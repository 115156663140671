/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar, defaults } from 'react-chartjs-2';
import { capitalize } from 'lodash';

function DealStatsBySize({ ordersStatsBySize }) {
  const [inputData, setInputData] = useState([]);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const inp = ordersStatsBySize;
    const dd = inp.map((val) => {
      const elem = {
        label: val[0].label,
      };
      val[0].orders.forEach((ord) => {
        elem[capitalize(ord.status)] = ord.count;
      });
      return elem;
    });
    setInputData(dd);
  }, [ordersStatsBySize]);

  useEffect(() => {
    const data = {
      labels: inputData.map((order) => order.label),
      datasets: [
        {
          label: 'Pending',
          data: inputData.map((order) => order.Pending),
          backgroundColor: '#ffbb23',
        },
        {
          label: 'Application',
          data: inputData.map((order) => order.Application),
          backgroundColor: '#3b89fe',
        },
        {
          label: 'Checkout',
          data: inputData.map((order) => order.Checkout),
          backgroundColor: '#0d8143',
        },
        {
          label: 'Agreement',
          data: inputData.map((order) => order.Agreement),
          backgroundColor: '#99CCCC',
        },
        {
          label: 'Financed',
          data: inputData.map((order) => order.Financed),
          backgroundColor: '#2dc26c',
        },
      ],
    };

    setDeals(data);
  }, [inputData]);

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: defaults.global.defaultFontSize,
      },
    },
    type: 'bar',
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            padding: 10,
            callback: (value) => {
              return [
                value.substring(0, value.lastIndexOf(' ')),
                value.substring(value.lastIndexOf(' ')).trim(),
              ];
            },
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
            maxTicksLimit: 6,
          },
        },
        {
          display: false,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            padding: 10,
            maxTicksLimit: 6,
          },
        },
      ],
    },
  };

  return <Bar data={deals} width={100} height={50} options={options} />;
}

DealStatsBySize.propTypes = {
  ordersStatsBySize: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        orders: PropTypes.arrayOf(
          PropTypes.shape({
            status: PropTypes.string,
            count: PropTypes.number,
          })
        ),
      })
    )
  ),
};

DealStatsBySize.defaultProps = {
  ordersStatsBySize: [],
};

export default DealStatsBySize;
