/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useMemo, useState } from 'react';

import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { Card, Typography, ButtonV2 } from '@vartanainc/design-system';
import { get } from 'lodash';
import { useBeforeunload } from 'react-beforeunload';
import { useQuery, useMutation } from '@apollo/client';

import { useFormik, FormikProvider } from 'formik';

import * as yup from 'yup';
import CancelConfirmationModal from '../../../../components/Modals/CancelConfirmation';
import FormToggle from '../../../../components/FormToggle';
import AutoLoad from '../../../../components/AutoLoad';

import { ReactComponent as ContactIcon } from '../../../../assets/contact-icon-blue.svg';
import { formatCapital, showErrorToast, showToast } from '../../../../utils/helpers';
import { AddressValidator } from '../../../../utils/validators';
import { COUNTRY_NAME_TO_STATES_MAP } from '../../../../static';

import {
  GET_CONTACT_BY_NUMBER,
  UPDATE_CONTACT,
  CREATE_CONTACT,
} from '../../../../graphql/queries/contact';
import {
  requiredPhoneFieldValidation,
  zipSchema,
} from '../../../../constants/common.constants';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import AddressInputField from '../../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { useSetAddressFields } from '../../../../utils/hooks';

const ContactFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: requiredPhoneFieldValidation,
  primary: yup.bool().default(false).required('This field is required'),
  emailPreferences: yup.object().shape({
    receiveInvoice: yup.bool().default(false).required('This field is required'),
  }),
  ...AddressValidator,
  country: yup.string().required('Country is required'),
  zip: zipSchema,
});

function ContactEdit() {
  const { contactNumber, companyNumber } = useParams();
  const navigate = useNavigate();

  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [createContact] = useMutation(CREATE_CONTACT);

  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { customerData } = useOutletContext();
  const customerCountry = useMemo(
    () => get(customerData, 'company.country'),
    [customerData]
  );

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    primary: false,
    isOwner: false,
    emailPreferences: {
      receiveInvoice: false,
    },
    street: '',
    city: '',
    state: '',
    zip: '',
    country: customerCountry,
  });

  const { data: contactDetails, loading: contactLoading } = useQuery(
    GET_CONTACT_BY_NUMBER,
    {
      skip: !contactNumber,
      variables: {
        number: contactNumber,
        companyNumber,
      },
    }
  );

  useEffect(() => {
    if (!contactLoading && contactDetails) {
      setInitialValues({
        firstName: get(contactDetails, 'user.firstName', ''),
        lastName: get(contactDetails, 'user.lastName', ''),
        phone: get(contactDetails, 'user.formattedPhone', ''),
        email: get(contactDetails, 'user.email', ''),
        primary: !!get(contactDetails, 'user.primary', false),
        isOwner: get(contactDetails, 'user.isOwner', false),
        emailPreferences: {
          receiveInvoice: get(
            contactDetails,
            'user.emailPreferences.receive_invoice',
            false
          ),
        },
        street: get(contactDetails, 'user.defaultAddress.street', ''),
        city: get(contactDetails, 'user.defaultAddress.city', ''),
        state: get(contactDetails, 'user.defaultAddress.state', ''),
        zip: get(contactDetails, 'user.defaultAddress.zip', ''),
      });
    }
  }, [contactDetails, contactLoading, setInitialValues]);

  async function onSubmit(values, { setErrors }) {
    try {
      let responseData;
      if (contactNumber) {
        responseData = await updateContact({
          variables: {
            companyNumber,
            contactNumber,
            ...values,
          },
        });
      } else {
        responseData = await createContact({
          variables: {
            companyNumber,
            ...values,
          },
        });
      }
      const newContactNumber = get(
        responseData,
        'data.createContact.number',
        contactNumber
      );
      const fullName =
        formatCapital(get(responseData, 'data.createContact.fullName', '')) ||
        formatCapital(get(responseData, 'data.updateContact.fullName', ''));
      const errors = get(responseData, 'errors', {});
      if (Object.keys(errors).length) setErrors(errors);
      else {
        const message = `${contactNumber ? 'Contact updated!' : 'New contact added!'} ${
          contactNumber
            ? `${fullName} was updated!`
            : `${fullName} is now added to contacts.`
        }`;
        setCanSafelyExit(true);
        setTimeout(() => {
          showToast('success', message);
          navigate(`/dashboard/customers/${companyNumber}/contacts/${newContactNumber}`);
        }, 0);
      }
    } catch (e) {
      showErrorToast();
    }
  }

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ContactFormSchema,
    onSubmit,
  });

  const { isSubmitting, isValid, dirty, setFieldValue, validateField, handleSubmit } =
    formikBag;
  const setSelectedPlace = useSetAddressFields(setFieldValue, validateField, true);

  const disabled = isSubmitting || !isValid;

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  function onCancel() {
    let url = `/dashboard/customers/${companyNumber}/contacts`;
    if (contactNumber) url += `/${contactNumber}`;
    navigate(url);
  }

  return (
    <>
      <div className="pt-10 h-screen bg-vartana-steel-20 grid grid-cols gap-x-4">
        <CancelConfirmationModal
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onConfirm={() => onCancel()}
        />
        <FormikProvider value={formikBag}>
          <form
            className="gap-x-4 h-full w-full bg-vartana-steel-20"
            onSubmit={handleSubmit}
            onKeyUp={() => {
              setCanSafelyExit(!dirty);
            }}
          >
            <fieldset
              disabled={isSubmitting}
              className="w-7/12 mx-auto small-card-width-range"
            >
              <Card
                tabindex={0}
                variant="fullWidth"
                containerClassName=""
                title={(
                  <div className="flex items-center gap-1 mb-2">
                    <ContactIcon />
                    <Typography variant="heading20" color="color-black-100">
                      {contactNumber ? 'Edit contact information' : 'Add new contact'}
                    </Typography>
                  </div>
                )}
                content={(
                  <div>
                    <div className="space-y-8">
                      <div className="space-y-4">
                        <div className="flex flex-row space-x-4">
                          <TextInputField
                            name="firstName"
                            label="First name"
                            placeholder="First name"
                          />

                          <TextInputField
                            name="lastName"
                            label="Last name"
                            placeholder="Last name"
                          />
                        </div>

                        <div className="flex flex-row space-x-4">
                          <TextInputField
                            name="email"
                            label="Email"
                            placeholder="example@email.com"
                          />

                          <PatternInput
                            name="phone"
                            label="Phone"
                            disableFullStoryRecording
                            mask="_"
                            value={formikBag.values.phone}
                          />
                        </div>

                        <div className="flex space-x-4">
                          <AddressInputField
                            name="street"
                            label="Address (Optional)"
                            afterPlaceSelect={(tempSelectedPlace) =>
                              setSelectedPlace(tempSelectedPlace)}
                            countries={[customerCountry]}
                          />
                        </div>

                        <div className="flex space-x-4">
                          <TextInputField id="city" name="city" label="City" />
                          <CustomDropdown
                            id="state"
                            name="state"
                            label="State"
                            options={COUNTRY_NAME_TO_STATES_MAP[customerCountry]}
                          />
                          <TextInputField id="zip" name="zip" label="Zip" />
                        </div>

                        <div className="flex flex-row space-x-4">
                          <FormToggle
                            name="primary"
                            label="Primary contact"
                            tooltipElement={(
                              <Typography className="text-left" variant="paragraph12">
                                The individual responsible for <br />
                                communicating on behalf of their
                                <br />
                                company.
                              </Typography>
                            )}
                            className="flex-1"
                            disabled
                          />
                          <FormToggle
                            name="emailPreferences.receiveInvoice"
                            label="Receive invoice"
                            className="flex-2"
                          />
                        </div>
                      </div>
                    </div>
                    <AutoLoad loading={isSubmitting}>
                      <div className="flex flex-row justify-between items-center pt-6">
                        <ButtonV2
                          variant={{ type: 'ghost', typography: 'paragraph14' }}
                          type="button"
                          text="Cancel"
                          iconLeft="chevron_left"
                          onClick={() => {
                            if (dirty) {
                              setOpenConfirmationModal(true);
                            } else onCancel();
                          }}
                        />
                        <ButtonV2
                          type="submit"
                          disabled={disabled}
                          text="Submit"
                          variant={{ type: 'primary', typography: 'paragraph14' }}
                        />
                      </div>
                    </AutoLoad>
                  </div>
                )}
              />
            </fieldset>
          </form>
        </FormikProvider>
      </div>
    </>
  );
}

export default ContactEdit;
