export const progenDefaultTexts = {
  installment_tab_label: 'Offer installment plan',
  defer_tab_label: 'Defer payment',
  signature_tab_label: 'Send for signature',
  pg_description: 'Personal guaranty required',
  calculator_onetime_fee: 'A one-time documentation fee.',
  calculator_pg_tooltip:
    'An individual with ownership or executive responsibilities in the company who can guarantee the payment agreement with personal credit in case the business defaults.',
  frequency_labels: {
    annual: 'annually',
    quarterly: 'per quarter',
    monthly: 'per month',
  },
};
