import { createContext } from 'react';

export const OrderShowContext = createContext({
  selectedCustomer: {},
});
export const OrderFormContext = createContext({
  proposalErrors: [],
  selectedCustomer: {},
  selectedOrder: {},
  setProposalErrors: () => {},
  availableTermsLoading: false,
  fetchAvailableTerms: () => {},
  startDate: '',
});
