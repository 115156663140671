import { useSearchParams } from 'react-router-dom';
import { Typography } from '@vartanainc/design-system';
import { titleize } from '../../../utils/helpers';
import chevronIcon from '../../../assets/chevron.svg';
import { InviteResellerCard } from '../../../components/Cards';

export function InviteResellerForm() {
  const [searchParams] = useSearchParams();

  const customerNumber = searchParams.get('customerNumber');
  const customerName = searchParams.get('customerName');

  return (
    <div className="flex flex-col divide-y gap-6">
      <header className="flex gap-2">
        <Typography variant="heading24" color="color-black-100">
          {titleize(customerName)}
        </Typography>
        <img alt="chevron" src={chevronIcon} />
        <Typography variant="heading24" color="color-black-100">
          Add partner
        </Typography>
      </header>

      <main className="py-4">
        <InviteResellerCard
          customerNumber={customerNumber}
          customerName={customerName}
          app="widget"
        />
      </main>
    </div>
  );
}
