import {
  CompoundUnitInputField,
  CompoundUnitInputFieldProps,
  Typography,
} from '@vartanainc/design-system';
import { useField } from 'formik';
import { forwardRef } from 'react';
import './compactField.scss';

interface SubsidyFieldProps extends CompoundUnitInputFieldProps {
  isCompact?: boolean;
  errorMessage?: string;
}

const SubsidyField = forwardRef(
  ({
    id,
    name = '',
    label,
    type,
    options,
    className = '',
    isCompact = true,
    errorMessage,
    ...rest
  }: SubsidyFieldProps) => {
    const field = useField(name);

    function getBorderClassName(showError?: boolean, disabled?: boolean): string {
      if (disabled) {
        return 'disabled-border';
      }
      if (showError) {
        return 'error-border';
      }
      return '';
    }

    return (
      <>
        <CompoundUnitInputField
          {...rest}
          {...field}
          id={id}
          name={name}
          label={label}
          options={options}
          className={
            isCompact
              ? `${className} compact-compound-unit-field`
              : `${className} not-compact ${getBorderClassName(
                  rest.showError,
                  rest.disabled
                )}`
          }
        />
        {errorMessage && (
          <Typography color="color-red-160" variant="paragraph10">
            {errorMessage}
          </Typography>
        )}
      </>
    );
  }
);

export default SubsidyField;
