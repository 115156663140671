import React, { useMemo, useContext, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { Card } from '@vartanainc/design-system';
import { twMerge } from 'tailwind-merge';

import SendCheckoutLinkForm from '../SendCheckoutLinkForm';
import TrackRecipients from '../../Orders/TrackStatus/TrackRecipients';
import { OnlyCustomerContext } from '../../../context/OnlyCustomerContext';
import { WidgetContext } from '../../../context/WidgetContext';
import CommonWidgetV2 from '../../CommonWidgetV2/commonWidget';
import { appVariants } from '../../../constants/common.constants';

function TrackStatus() {
  const { customerData } = useOutletContext();
  const appraisalData = get(customerData, 'company.creditAppraisal', {});
  const companyNumber = get(customerData, 'company.number', '');
  const [formFocusSwitch, setFormFocusSwitch] = useState(false);

  const trackRecipients = useMemo(() => {
    const emailHistory = get(appraisalData, 'emailHistory', []);
    if (emailHistory.length) {
      return (
        <Card
          variant="variable"
          content={(
            <TrackRecipients
              emailHistory={emailHistory.map((email) => ({ id: uuidv4(), ...email }))}
            />
          )}
        >
        </Card>
      );
    }
    return null;
  }, [appraisalData]);

  // v4 progen start from here
  const customerDataProgen = useContext(OnlyCustomerContext);
  const [selectedTerm, setSelectedTerm] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [widgetBorderStyle, setWidgetBorderStyle] = useState('');

  const widgetContext = useMemo(
    () => ({
      selectedOrder: {},
      screen: 'track',
      selectedCompany: get(customerDataProgen, 'company'),
      product: get(customerDataProgen, 'company.seller.product', {}),
      variant: appVariants.vendor,
      setFormFocus: () => setFormFocusSwitch((e) => !e),
      selectedTerm,
      setSelectedTerm,
      selectedFrequency,
      setSelectedFrequency,
      setWidgetBorderStyle,
    }),
    [
      customerDataProgen,
      selectedTerm,
      setSelectedTerm,
      selectedFrequency,
      setSelectedFrequency,
    ]
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-6 h-full">
      <SendCheckoutLinkForm
        appraisalNumber={companyNumber}
        disabled={get(appraisalData, 'loanDecision') !== 'need_information'}
        formFocusSwitch={formFocusSwitch}
      />
      <div className="flex flex-col gap-6 w-max">
        <div
          className={twMerge(
            'vd-customer-track-container relative bg-white shadow-lg rounded-lg  border-[1.5px] flex justify-center border-solid max-w-max',
            widgetBorderStyle
          )}
        >
          <WidgetContext.Provider value={widgetContext}>
            <CommonWidgetV2 />
          </WidgetContext.Provider>
        </div>
        {trackRecipients}
      </div>
    </div>
  );
}

export default TrackStatus;
