import React, { useState, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { useDirectUploadFiles } from '../../../utils/hooks';

import { OrderShowContext } from '../../../context/OrderContext';
import UploadDocumentModal from '../../../components/Modals/UploadDocumentModal';
import CustomNotification from '../../../components/CustomNotification';
import ActionRequired from '../Summary/ActionRequired';

function FundingInvoice({ orderMeta }) {
  const { refetchOrderData } = useContext(OrderShowContext);

  const [directUploadFiles] = useDirectUploadFiles();
  const [showDocumentUploadModal, toggleDocumentUploadModal] = useState(false);
  const orderFinanced = useMemo(
    () => get(orderMeta, 'status', '') === 'financed',
    [orderMeta]
  );

  const onInvoiceSubmit = useCallback(
    async (values) => {
      await directUploadFiles(
        [...values.documents],
        {
          documentType: 'funding_invoice',
        },
        {
          id: get(orderMeta, 'id', ''),
          type: 'Order',
        }
      );
      await refetchOrderData();
      toggleDocumentUploadModal(false);
      toast.success(({ toastProps }) => {
        return (
          <CustomNotification
            type={toastProps.type}
            message={`Vendor invoice submitted! ${
              orderFinanced
                ? 'Vartana will shortly process remaining payout as per invoice amount.'
                : 'Vartana is processing your order.'
            }`}
          />
        );
      });
    },
    [
      toggleDocumentUploadModal,
      refetchOrderData,
      directUploadFiles,
      orderMeta,
      orderFinanced,
    ]
  );

  return (
    <>
      <UploadDocumentModal
        open={showDocumentUploadModal}
        onClose={() => toggleDocumentUploadModal(false)}
        title="Upload vendor invoice"
        onSubmit={onInvoiceSubmit}
      />
      <ActionRequired
        title="Awaiting invoice!"
        message={`Please upload invoice to receive ${
          orderFinanced ? 'remaining' : ''
        } payout.`}
        secondaryMessage={orderFinanced ? null : orderMeta.updatedAt}
        onPrimaryClick={() => toggleDocumentUploadModal(true)}
        primaryButtonText="Upload invoice"
      />
    </>
  );
}

FundingInvoice.propTypes = {
  orderMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default FundingInvoice;
