/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { get } from 'lodash';

import { useReactiveVar } from '@apollo/client';
import { TABLE_EMPTY_PLACEHOLDER } from '../../../../constants/common.constants';
import {
  getStatusClass,
  formatCapital,
  getUSDateFormat,
  humanizeName,
  getTextForNumberOfDays,
} from '../../../../utils/helpers';

import Table from '../../../../components/Table';
import SortableHeader from '../../../../components/Table/SortableHeader';
import { BlueLink } from '../../../../components/Link';
import usePermissions from '../../../../utils/hooks/permissions';
import { ReactComponent as NeedInfoIcon } from '../../../../assets/need_info_big.svg';
import { isHighlighted } from '../../../Orders/order.utils';
import { ALLOWED_STATES_TO_EXPIRE } from '../../../Orders/order.constants';
import ZeroState from '../../../../components/ZeroState/ZeroState';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';
import { sessionVar } from '../../../../graphql/cache';

function CustomerOrders() {
  const [, userHasPermission] = usePermissions();
  const { customerData, customerLoading: loading } = useOutletContext();
  const customer = get(customerData, 'company', {});
  const orders = get(customerData, 'company.buyerOrders', []);

  const sessionData = useReactiveVar(sessionVar);
  const showCurrency = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );

  const columns = useMemo(() => {
    const cols = [
      {
        Header: '',
        accessor: 'icon.src',
        Cell: ({ row }) => (
          <div>{isHighlighted(row.original) ? <NeedInfoIcon /> : null}</div>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Order #" />,
        accessor: 'number',
        Cell: (props) => (
          <Link to={`/dashboard/orders/${props.value}/summary`}>
            <p className="break-all vp-text-link-bold  text-vartana-blue-60">
              {props.value}
            </p>
          </Link>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Amount" />,
        accessor: 'amount.amount',
        Cell: (props) => {
          const orderAmount = get(props, 'row.original.amount', {});
          return (
            <p className="vp-body text-vartana-gray-60">
              {orderAmount.amount ? orderAmount.formattedAmount : TABLE_EMPTY_PLACEHOLDER}
            </p>
          );
        },
      },
      {
        Header: (props) => <SortableHeader props={props} title="Term" />,
        accessor: 'term.raw',
        Cell: (props) => {
          const term = get(props, 'row.original.term', {});
          return (
            <div className="vp-body text-vartana-gray-60">
              {term.raw ? term.formatted : TABLE_EMPTY_PLACEHOLDER}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'stage',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>{humanizeName(props.value)}</span>
        ),
      },
      {
        Header: 'Valid For',
        accessor: 'validity.raw',
        Cell: (props) =>
          ALLOWED_STATES_TO_EXPIRE.includes(props.row.original.stage) ? (
            <div className="flex items-start flex-col gap-1">
              <p className="vp-body text-vartana-black">{props.value}</p>
              <p className="vp-p-small text-vartana-gray-60">
                {props.row.original.validity.formatted}
              </p>
            </div>
          ) : (
            ''
          ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Owner" />,
        accessor: 'accountOwner',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            {userHasPermission('order', 'view_all', customer) ? (
              <BlueLink
                to="/dashboard/settings/users/view"
                state={{
                  selectedUser: props.row.original.accountOwner,
                }}
              >
                {formatCapital(props.row.original.accountOwner.fullName)}
              </BlueLink>
            ) : (
              <div className="vp-text-link-bold">
                {props.row.original.accountOwner.fullName}
              </div>
            )}
            <div className="vp-p-small">{props.row.original.accountOwner.email}</div>
          </div>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Updated" />,
        accessor: 'updatedAt.rawDate',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.row.original.updatedAt.formatted}
          </div>
        ),
      },
    ];

    if (showCurrency) {
      cols.splice(2, 0, {
        Header: 'Currency',
        accessor: 'currency',
        Cell: (props) => {
          const currency = get(props, 'row.original.currency', {});
          return <CurrencyPill currency={currency} />;
        },
      });
    }

    return cols;
  }, [showCurrency, userHasPermission, customer]);

  const data = useMemo(() => {
    let formattedOrderList = [];
    if (!loading) {
      formattedOrderList = orders.map((order) => {
        return {
          number: order.number,
          amount: {
            formattedAmount: order.hideOrderDetailsForCollaborator
              ? ''
              : order.formattedAmount,
            amount: order.amount,
          },
          currency: get(order, 'currency', {}),
          term: {
            raw: order.term,
            formatted: order.formattedTerm,
          },
          stage: order.vendorState,
          needFundingInvoice: get(order, 'canUploadFundingInvoice', false),
          loanDecision: order.loanDecision,
          validity: {
            raw: formatCapital(getTextForNumberOfDays(get(order, 'validForDays'))) || '',
            formatted: get(order, 'validUntil') || '',
          },
          updatedAt: {
            formatted: getUSDateFormat(order.updatedAt),
            rawDate: Date.parse(order.updatedAt),
          },
          accountOwner: {
            ...get(order, 'user', {}),
            fullName: get(order, 'user.fullName', null),
            email: get(order, 'user.email', ''),
          },
        };
      });
    }
    return formattedOrderList;
  }, [orders, loading]);

  const sortBy = useMemo(
    () => [
      { id: 'amount.amount' },
      { id: 'number' },
      { id: 'term.raw' },
      { id: 'updatedAt.rawDate' },
    ],
    []
  );

  const dataTable = useMemo(() => {
    if (data.length) {
      return (
        <Table
          columns={columns}
          data={data}
          sortBy={sortBy}
          getHeaderProps={() => ({
            className:
              'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
          })}
          getCustomRowProps={(row) => ({
            className: isHighlighted(row.original) ? 'bg-vartana-yellow-20' : '',
          })}
        />
      );
    }
    return (
      <div className="h-full w-full flex items-center">
        <ZeroState page="customer_order" cta="none" />
      </div>
    );
  }, [data, columns, sortBy]);

  return dataTable;
}

export default CustomerOrders;
