import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useRollbar } from '@rollbar/react';
import { Card } from '@vartanainc/design-system';
import AutoLoad from '../../components/AutoLoad';
import IntegrationCard from '../Customers/SyncWithIntegrations/IntegrationCard';
import { initializeTrayData, TRAY_ICON_KEY } from '../../api/tray';
import {
  activateIntegration,
  deactivateIntegration,
  handleTrayEvent,
} from '../Customers/SyncWithIntegrations/tray';
import TrayWizard from '../Customers/SyncWithIntegrations/TrayWizard';
import CommonModal from '../Customers/SyncWithIntegrations/CommonModal';
import SyncSuccessful from '../Customers/SyncWithIntegrations/SyncSuccessful';
import BeforeDeactivate from './BeforeDeactivate';
import AfterDeactivate from './AfterDeactivate';
import { showErrorToast } from '../../utils/helpers';

const screens = {
  crm: 'settings-screen',
  tray: 'tray-popup',
  syncSuccessful: 'success',
  beforeDeactivate: 'are you sure you want to deactivate',
  afterDeactivate: 'Deactivate Successful',
};
const modalStyle = 'absolute left-1/2 transform-gpu -translate-x-1/2 px-5 pt-5 w-5/12';

const IntegrationsPanel = ({
  loading,
  integrations,
  handleActivate,
  beforeDeactivate,
}) => {
  return (
    <div className="flex flex-col">
      {/* CRM Integrations Card */}
      <Card
        variant="fullWidth"
        title=" CRM integrations"
        content={(
          <AutoLoad loading={loading} className="my-16 w-20 h-20">
            <div className="overflow-x-auto flex flex-row justify-start gap-4">
              {integrations.length > 0
                ? integrations.map((integration) => {
                  return (
                    <IntegrationCard
                      imageIcon={(
                        <img
                          src={integration.iconUrl}
                          alt="CRM Logo"
                          width="70"
                          height="70"
                        />
                      )}
                      onClick={() => handleActivate(integration)}
                      onDeactivate={() => beforeDeactivate(integration)}
                      integration={integration}
                      buttonLabel="Activate"
                      key={integration.id}
                      inSettingsPage
                    />
                  );
                })
                : 'No Integrations available'}
            </div>
          </AutoLoad>
        )}
      >
      </Card>
    </div>
  );
};

IntegrationsPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleActivate: PropTypes.func.isRequired,
  beforeDeactivate: PropTypes.func.isRequired,
};

const IntegrationSettings = () => {
  const rollbar = useRollbar();

  const [trayUserId, setTrayUserId] = useState('');
  const [integrations, setIntegrations] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState({});

  const [initialWebhook, setInitialWebhook] = useState('');
  const [configWizardUrl, setConfigWizardUrl] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(screens.crm);

  useEffect(() => {
    setLoading(true);
    async function initialize() {
      try {
        const trayData = await initializeTrayData();
        setIntegrations(trayData.newIntegrations);
        setTrayUserId(trayData.userId);
      } catch (e) {
        showErrorToast();
        rollbar.error(`Error in getting integrations data: ${e}`, e);
      } finally {
        setLoading(false);
      }
    }
    initialize();
    return () => setIntegrations([]);
  }, [rollbar]);

  const handleActivate = useCallback(
    async (integration) => {
      setLoading(true);
      const { triggerUrl, trayWizardUrl, instance } = await activateIntegration(
        trayUserId,
        integration
      );

      setInitialWebhook(triggerUrl);
      setConfigWizardUrl(trayWizardUrl);
      setSelectedInstance(instance);
      setCurrentScreen(screens.tray);
    },
    [trayUserId]
  );

  const beforeDeactivate = useCallback((integration) => {
    setCurrentScreen(screens.beforeDeactivate);
    setSelectedInstance(integration);
  }, []);

  const handleDeactivate = useCallback(
    async (integration) => {
      setLoading(true);
      const trayData = await deactivateIntegration(trayUserId, integration);
      setIntegrations(trayData.newIntegrations);
      setTrayUserId(trayData.userId);
      setLoading(false);
    },
    [trayUserId]
  );

  const handleIframeEvent = useCallback(
    async (event) => {
      await handleTrayEvent({
        event,
        selectedInstance,
        trayUserId,
        initialWebhook,
        setIntegrations,
        onReady: () => setLoading(false),
        goBack: () => setCurrentScreen(screens.crm),
        goForward: () => setCurrentScreen(screens.syncSuccessful),
      });
    },
    [initialWebhook, selectedInstance, trayUserId]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeEvent);
    return () => window.removeEventListener('message', handleIframeEvent);
  }, [handleIframeEvent]);

  return (
    <div className="p-6 max-w-fit">
      {currentScreen === screens.crm && (
        <IntegrationsPanel
          loading={loading}
          integrations={integrations}
          handleActivate={handleActivate}
          beforeDeactivate={beforeDeactivate}
        />
      )}
      {currentScreen === screens.tray && (
        <TrayWizard
          isOpen={currentScreen === screens.tray}
          onClose={() => setCurrentScreen(screens.crm)}
          srcUrl={configWizardUrl}
          className={modalStyle}
        />
      )}
      {currentScreen === screens.syncSuccessful && (
        <CommonModal
          onClose={() => setCurrentScreen(screens.crm)}
          isOpen
          className={modalStyle}
          mainBody={(
            <SyncSuccessful
              isOpen={currentScreen === screens.syncSuccessful}
              onClose={() => setCurrentScreen(screens.crm)}
              activatedIntegration={selectedInstance?.solution}
              iconKey={TRAY_ICON_KEY}
              className={modalStyle}
            />
          )}
        />
      )}
      {currentScreen === screens.beforeDeactivate && (
        <CommonModal
          onClose={() => setCurrentScreen(screens.crm)}
          isOpen
          className={modalStyle}
          mainBody={(
            <BeforeDeactivate
              integration={selectedInstance}
              goBack={() => setCurrentScreen(screens.crm)}
              goForward={async () => {
                setCurrentScreen(screens.afterDeactivate);
                await handleDeactivate(selectedInstance);
              }}
            />
          )}
        />
      )}
      {currentScreen === screens.afterDeactivate && (
        <CommonModal
          onClose={() => setCurrentScreen(screens.crm)}
          isOpen
          className={modalStyle}
          mainBody={(
            <AutoLoad loading={loading} className="my-16 w-20 h-20">
              <AfterDeactivate
                integration={selectedInstance}
                goForward={() => setCurrentScreen(screens.crm)}
              />
            </AutoLoad>
          )}
        />
      )}
    </div>
  );
};

export default IntegrationSettings;
