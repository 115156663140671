// This file contains the constants used in the widgetV2 component

// This interface represents the response from the calculator
export interface CalculatorResponseProps {
  payment: string; // The calculated payment amount
  subsidy: string; // The calculated subsidy amount
}

export const WIDGET_TEXT_CONSTANTS = {
  PAYOUT_AFTER: 'Payout after:',
};

export const CUSTOMER_FORM_FIELD_NAMES = {
  MAX_UNITS: 'maxUnits',
  REQUESTED_AMOUNT: 'requestedAmount',
  REQUESTED_TERM: 'requestedTerm',
};

export const TWO_LEVEL_DECIMAL = 2;
