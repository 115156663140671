import { gql } from '@apollo/client';

export const CREATE_CREDIT_APPRAISAL = gql`
  mutation createCreditAppraisal($companyNumber: String!) {
    createCreditAppraisal(companyNumber: $companyNumber) {
      id
    }
  }
`;

export const UPDATE_BUSINESS_DETAILS_V2 = gql`
  mutation updateBusinessDetailsV2(
    $customerNumber: String!
    $businessName: String!
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $ein: String!
    $country: String!
  ) {
    updateBusinessDetailsV2(
      businessName: $businessName
      street: $street
      city: $city
      state: $state
      zip: $zip
      ein: $ein
      country: $country
      customerNumber: $customerNumber
    ) {
      id
    }
  }
`;

export const UPDATE_AUTHORIZED_SIGNER = gql`
  mutation upsertAuthorizedSignerV2(
    $authorizedSignerDetails: InputAuthorizedSigner!
    $customerNumber: String!
  ) {
    upsertAuthorizedSignerV2(
      authorizedSigner: $authorizedSignerDetails
      customerNumber: $customerNumber
    ) {
      id
    }
  }
`;

export const UPDATE_FINANCIALS = gql`
  mutation attachBlobsCreditApplication(
    $customerNumber: String!
    $documentBlobs: [MoreInfoFinancialDocumentUpload!]!
    $note: String
  ) {
    attachBlobsCreditApplication(
      customerNumber: $customerNumber
      documentBlobs: $documentBlobs
      note: $note
    ) {
      id
    }
  }
`;

export const MOVE_CREDIT_APPLICATION_TO_NEXT_STEP = gql`
  mutation MoveCreditApplicationToNextStep($customerNumber: String!) {
    moveCreditApplicationToNextStep(customerNumber: $customerNumber) {
      id
    }
  }
`;

export const MOVE_CREDIT_APPLICATION_TO_PREVIOUS_STEP = gql`
  mutation MoveCreditApplicationToPreviousStep($customerNumber: String!) {
    moveCreditApplicationToPreviousStep(customerNumber: $customerNumber) {
      id
    }
  }
`;

// Archive Documents mutation is used to remove documents from the credit appraisal application
export const ARCHIVE_DOCUMENTS = gql`
  mutation ($documentIds: [ID!]!) {
    archiveDocuments(documentIds: $documentIds) {
      id
    }
  }
`;

export const UPDATE_CREDIT_APPLICATION_SALES_QUOTE = gql`
  mutation updateCreditApplicationSalesQuote($customerNumber: String!, $note: String) {
    updateCreditApplicationSalesQuote(customerNumber: $customerNumber, note: $note) {
      id
    }
  }
`;
