import PropTypes from 'prop-types';

import { AlertBox, Typography } from '@vartanainc/design-system';
import { ReactComponent as ThumbsUpIcon } from '../../assets/thumbsup.svg';
import { ReactComponent as CyclicalArrowsIcon } from '../../assets/cyclical-arrows.svg';
import Button from '../Button';

// Can be removed in future: Retaining due to time limitation
function AppraisalAlertBox({ appraisalApproved, appraisalMsg }) {
  return (
    <div className="w-full">
      <AlertBox
        variant="alert-box"
        backgroundColor={appraisalApproved ? 'success' : 'review'}
        icon={
          appraisalApproved ? (
            <ThumbsUpIcon className="min-w-max" />
          ) : (
            <CyclicalArrowsIcon className="min-w-max" />
          )
        }
        content={(
          <Typography variant="paragraph10">
            {appraisalMsg.message}&nbsp;
            {appraisalMsg.callToActionText !== '' && (
              <Button
                onClick={appraisalMsg.callToActionFunction}
                className="text-vartana-blue-120"
                element={appraisalMsg.callToActionText}
              />
            )}
          </Typography>
        )}
      >
      </AlertBox>
    </div>
  );
}

AppraisalAlertBox.defaultProps = {
  appraisalMsg: PropTypes.shape({
    message: '',
    callToActionText: '',
    callToActionFunction: null,
  }),
};

AppraisalAlertBox.propTypes = {
  appraisalApproved: PropTypes.bool.isRequired,
  appraisalMsg: PropTypes.shape({
    message: PropTypes.string,
    callToActionText: PropTypes.string,
    callToActionFunction: PropTypes.func,
  }),
};

export default AppraisalAlertBox;
