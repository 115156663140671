import { twMerge } from 'tailwind-merge';
import vartanaLoaderGif from '../../assets/vartanaLoader.gif';

interface VartanaLoaderProps {
  className: string;
  containerClassName: string;
  fullscreen: boolean;
  loading: boolean;
}

/**
 * Component for displaying a loader animation.
 *
 * @param {string} className Additional CSS class names for styling the loader image.
 * @param {string} containerClassName Additional CSS class names for styling the loader container.
 * @param {boolean} fullscreen Indicates whether the loader should cover the entire screen.
 * @param {boolean} loading Indicates whether the loader should be displayed.
 */
function VartanaLoader({
  className,
  containerClassName,
  fullscreen,
  loading,
}: VartanaLoaderProps): JSX.Element | null {
  if (loading) {
    return (
      <div
        className={`${twMerge(containerClassName, 'w-full h-full')} ${
          fullscreen ? 'fixed' : 'absolute'
        } z-10 bg-white bg-opacity-80 top-0 left-0 flex items-center justify-center`}
      >
        <img
          src={vartanaLoaderGif}
          alt="loader"
          className={twMerge(className, 'h-[6.25rem] w-[6.25rem]')}
        />
      </div>
    );
  }
  return null;
}

export default VartanaLoader;
