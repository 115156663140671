import React, { useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ConfettiContext } from '../../context/ConfettiContext';
import AutoLoad from '../../components/AutoLoad';

function CRM({ isLoading }) {
  const [confettiCounter, setConfettiCounter] = useState(0);

  const confettiContext = useMemo(
    () => ({
      // needs to be constant across rerenders
      confettiCounter,
      setConfettiCounter,
    }),
    [confettiCounter, setConfettiCounter]
  );

  return (
    <ConfettiContext.Provider value={confettiContext}>
      <div className="bg-white rounded-lg h-screen">
        <AutoLoad loading={isLoading} containerClassName="absolute top-1/2 left-1/2">
          <Outlet />
        </AutoLoad>
      </div>
    </ConfettiContext.Provider>
  );
}

CRM.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default CRM;
