import { gql } from '@apollo/client';

export const GET_APPROVED_OFFERS = gql`
  query getApprovedOffers($number: String!) {
    company(number: $number) {
      id
      number
      buyerMinAllowedOrderAmount
      formattedBuyerMinAllowedOrderAmount
      creditAppraisal {
        number
        approvedCreditTermsFormatted
        allowedBillingFrequencies
        allowedPaymentTerms
        currentStepDetails
        maxTerm
        applicationRequired
        loanDecision
        updatedAtFormatted
        moreInfo
        pgRequired
        expired
        conditionalToPg
      }
    }
  }
`;

export const GET_COMPANY_BY = gql`
  query getCompanyBy($number: String, $crmAccountId: String) {
    companyBy(number: $number, crmAccountId: $crmAccountId) {
      id
      number
      name
      buyerMinAllowedOrderAmount
      buyerRelationToVendor
      formattedBuyerMinAllowedOrderAmount
      vendorAvailablePaymentOptions
      pricingExpired
      pricingExpiredData
      seller {
        name
        resellerPermissions
        downloadProposalOnlyWhenApproved
        product {
          ...ProductDetails
        }
      }
      creditAppraisal {
        id
        number
        approvedCreditTermsFormatted
        className
        allowedBillingFrequencies
        allowedPaymentTerms
        currentStepDetails
        maxTerm
        applicationRequired
        loanDecision
        updatedAtFormatted
        moreInfo
        pgRequired
        conditionalToPg
        expired
        currency
        changeRequestStatus
        declineReasonsWithStatus
        creditAppraisalChangeRequest {
          id
        }
        creditApplication {
          state
        }
      }
    }
  }
`;

export const GET_APPROVED_CREDIT_PAYMENT_TERM = gql`
  query getApprovedCreditPaymentTerm($number: String!) {
    getApprovedCreditPaymentTerms(number: $number)
  }
`;
