import { useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Modal from 'react-modal';
import { useReactiveVar } from '@apollo/client';

import { Button, Typography } from '@vartanainc/design-system';
import { useRollbar } from '@rollbar/react';
import { get, isEmpty, omit } from 'lodash';
import { ReactComponent as BankIcon } from '../../../../assets/bank-icon.svg';
import { paymentModes, plaidConnectionStates, useUpsertPaymentMethod } from './utils';
import { BankConnectingOptions } from './BankConnectingOptions';
import { ManualBankForm } from './ManualBankForm';
import { BankCard } from './BankCard';
import AutoLoad from '../../../../components/AutoLoad';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import { useDirectUploadFiles } from '../../../../utils/hooks';
import { BackButton } from '../../../../components/Button/BackButton';
import Loader from '../../../../components/Loader';
import { isRenderedFromHubspot } from '../../../../utils/helpers';
import { sessionVar } from '../../../../graphql/cache';
import { COUNTRY } from '../../../../static';
import {
  EIN_FIELD_LENGTH,
  INSTITUTION_NUMBER_LENGTH,
  TRANSIT_NUMBER_LENGTH,
} from '../../../../constants/common.constants';

const accountTypes = [
  { value: 'checking', label: 'Checking' },
  { value: 'savings', label: 'Savings' },
];

const REQUIRED_FIELD_ERROR = 'This field is required';

const validationSchema = Yup.object({
  bankName: Yup.string().required(REQUIRED_FIELD_ERROR),
  accountName: Yup.string().required(REQUIRED_FIELD_ERROR),
  accountType: Yup.string()
    .required(REQUIRED_FIELD_ERROR)
    .oneOf(accountTypes.map((obj) => obj.value)),
  routingNumber: Yup.string().when('isEft', {
    is: false,
    then: Yup.string()
      .required(REQUIRED_FIELD_ERROR)
      .test(
        'length',
        'Must be exactly 9 digits',
        (value) => value && value.length === EIN_FIELD_LENGTH
      ),
  }),
  accountNumber: Yup.string()
    .min(6, 'Too Short!')
    .max(17, 'Too Long!')
    .required(REQUIRED_FIELD_ERROR),
  confirmAccountNumber: Yup.string()
    .required(REQUIRED_FIELD_ERROR)
    .oneOf([Yup.ref('accountNumber'), null], "Account number doesn't match"),
  documents: Yup.array()
    .required('Document is required')
    .test('Emptiness', 'Document is required', (val) => !isEmpty(val)),
  transitNumber: Yup.string()
    .nullable()
    .when('isEft', {
      is: true,
      then: Yup.string()
        .required(REQUIRED_FIELD_ERROR)
        .test(
          'length',
          'Must be exactly 5 digits',
          (value) => value && value.length === TRANSIT_NUMBER_LENGTH
        ),
    }),
  institutionNumber: Yup.string()
    .nullable()
    .when('isEft', {
      is: true,
      then: Yup.string()
        .required(REQUIRED_FIELD_ERROR)
        .test(
          'length',
          'Must be exactly 3 digits',
          (value) => value && value.length === INSTITUTION_NUMBER_LENGTH
        ),
    }),
});

const initialValues = {
  bankName: '',
  accountName: '',
  accountType: accountTypes[0].value,
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  documents: undefined,
};

const formatPayload = (formData) => {
  return {
    ...formData,
    bank: formData.bankName,
    accountType: formData.accountType?.toLowerCase() || '',
  };
};

export const PaymentInfoModal = ({ isOpen, onClose, onSuccess, orderId }) => {
  const sessionData = useReactiveVar(sessionVar);
  const isCanadianVendor =
    get(sessionData, 'session.user.company.country', '') === COUNTRY.CA;

  const renderedFromHubspot = isRenderedFromHubspot();
  const rollbar = useRollbar();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(paymentModes.ach);
  const [plaidState, setPlaidState] = useState(plaidConnectionStates.INIT);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [upsertPaymentMethod] = useUpsertPaymentMethod();
  const [directUploadFiles] = useDirectUploadFiles();

  const showManualBankForm = selectedPaymentMode === paymentModes.ach;

  const handleClose = () => {
    setSelectedPaymentMode(paymentModes.ach);
    setPlaidState(plaidConnectionStates.INIT);
    onClose();
  };

  const handleSubmit = useCallback(
    async (formData, { setErrors }) => {
      try {
        setSubmitLoading(true);

        // First, upload funding invoice provided by user
        await directUploadFiles(
          [...formData.documents],
          { documentType: 'funding_invoice' },
          { id: orderId, type: 'Order' }
        );

        // Next, upsert the bank info provided by user
        const omittedFields = ['isEft'];
        const payloadValues = omit(formData, omittedFields);
        if (isCanadianVendor) {
          payloadValues.contactName = 'Plaid Contact';
        }

        const payload = {
          paymentMode: isCanadianVendor ? paymentModes.eft : selectedPaymentMode,
          ...payloadValues,
        };

        await upsertPaymentMethod({
          payload: formatPayload(payload),
          verificationRequired: true,
          onError: (errors) => setErrors(errors),
        });

        onSuccess();
      } catch (e) {
        rollbar.warn(
          `While submitting payment info for Order ID# ${orderId}: ${e.message}`
        );
      } finally {
        setSubmitLoading(false);
      }
    },
    [
      directUploadFiles,
      orderId,
      selectedPaymentMode,
      upsertPaymentMethod,
      onSuccess,
      rollbar,
      isCanadianVendor,
    ]
  );

  const modalClassName = useMemo(() => {
    return renderedFromHubspot ? 'hubspot-payment-info-modal' : '';
  }, [renderedFromHubspot]);

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={twMerge(
        'flex justify-center items-center fixed inset-0 bg-vartana-gray-70/80',
        modalClassName
      )}
      className="w-full max-w-screen-md h-screen md:h-fit md:max-h-95vh overflow-y-auto outline-0 bg-white rounded-lg"
    >
      <Formik
        initialValues={{ ...initialValues, isEft: isCanadianVendor }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { dirty, isValid, resetForm, values } = formik;
          const enabled = dirty && isValid;

          return (
            <Form className="p-10">
              <div className="flex flex-col gap-2">
                <header className="flex items-center pb-2 lg:pb-4 gap-2 lg:gap-4">
                  <BankIcon className="w-8 h-8" />
                  <Typography variant="heading20" color="color-black-100">
                    Payout information
                  </Typography>
                </header>
                <Typography variant="paragraph14">
                  Please provide the following information to receive payout for your
                  order.
                </Typography>

                <div className="flex flex-col gap-3 mt-4">
                  <BankConnectingOptions
                    setPlaidInProgress={plaidState === plaidConnectionStates.LOADING}
                    onConnectPlaid={({ state, payload }) => {
                      setPlaidState(state);
                      formik.setValues({
                        ...payload,
                        bankName: payload?.bank || '',
                        confirmAccountNumber: payload?.accountNumber || '',
                      });
                    }}
                    onInstant={() => {
                      resetForm();
                      setPlaidState(plaidConnectionStates.INIT);
                      setSelectedPaymentMode(paymentModes.plaid);
                    }}
                    onManual={() => {
                      resetForm();
                      setPlaidState(plaidConnectionStates.INIT);
                      setSelectedPaymentMode(paymentModes.ach);
                    }}
                    isEft={isCanadianVendor}
                  />
                </div>

                {showManualBankForm ? (
                  <ManualBankForm
                    accountTypes={accountTypes}
                    formik={formik}
                    isEft={isCanadianVendor}
                  />
                ) : (
                  <AutoLoad
                    loading={plaidState === plaidConnectionStates.LOADING}
                    containerClassName="my-5"
                  >
                    <BankCard
                      className="mt-4"
                      selectedAccount={{
                        ...values,
                        paymentMode: selectedPaymentMode,
                      }}
                      variant="primary"
                    />
                  </AutoLoad>
                )}
              </div>
              <div className="mt-6">
                <MultiFileUpload label="Funding Invoice" name="documents" />
              </div>

              <div className="flex justify-between gap-3 w-full pt-8">
                <BackButton
                  btnText="Cancel"
                  onClick={() => {
                    setSelectedPaymentMode('');
                    handleClose();
                  }}
                />

                <Button type="submit" disabled={!enabled || submitLoading}>
                  {submitLoading ? <Loader className="p-3" /> : 'Submit'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

PaymentInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default PaymentInfoModal;
