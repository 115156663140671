import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { get, isEmpty } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';
import { Form, Formik } from 'formik';
import { Typography, Button, ButtonV2 } from '@vartanainc/design-system';

import { sessionVar } from '../../../../graphql/cache';
import AutoLoad from '../../../../components/AutoLoad';
import { useUploadProposalVendorDocs } from '../../../../utils/hooks';
import {
  checkIsVartanaFinancingAllowed,
  initialOrderFormValues,
  initialProposalObj,
  initialResellerFormValues,
  isProposalEmpty,
  getOrderFormSchema,
} from '../../../Orders/order.constants';
import {
  CONFIRMATION_SCREEN_VARIANTS,
  orderScreens,
} from '../../../../constants/common.constants';
import {
  CREATE_ORDER_WITH_PROPOSALS,
  GET_NEW_ORDER_DETAILS,
} from '../../../../graphql/queries/order';
import SelectCustomer from '../../../Orders/selectCustomer';
import AuthorizedSigner from '../../../Orders/AuthorizedSigner';
import {
  showToast,
  showErrorToast,
  reportError,
  titleize,
  getPageUrl,
} from '../../../../utils/helpers';
import { GET_VENDOR_PRODUCT } from '../../../../graphql/queries/product';
import { OrderHeading } from '../../../Orders/OrderHeading';
import { OrderForm } from '../../../Orders/OrderForm';
import { ResellerForm } from '../../../../macro_components/ResellerForm/ResellerForm';
import { OrderFormContext } from '../../../../context/OrderContext';
import { useOrderCreation } from '../../../../utils/hooks/order_creation';
import { generateDocsMeta, getProposalPayload } from '../../../Orders/order.utils';

function Order() {
  const navigate = useNavigate();
  const { update: updateIntercom } = useIntercom();
  const [proposalErrors, setProposalErrors] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({
    ...initialOrderFormValues,
    ...initialResellerFormValues,
  });
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const {
    validationSchema,
    currentScreen,
    resellerMode,
    toggleResellerMode,
    availableTermsLoading,
    fetchAvailableTerms,
    handleNext,
    handleBack,
    setValidationSchema,
    getProposalSpiffRate,
    isDirectOrder,
  } = useOrderCreation(selectedCustomer);
  const [companyList, setCompanyList] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isVartanaFinancingCheckBoxDisabled, setIsVartanaFinancingCheckBoxDisabled] =
    useState(false);
  const [startDate, setStartDate] = useState();
  const [defaultProposalValues, setDefaultProposalValues] = useState(initialProposalObj);
  const [spiffError, setSpiffError] = useState(null);
  const [productConfig, setProductConfig] = useState({
    availableOrderTypes: [],
    availablePaymentContractLengths: [],
    availablePaymentFrequencies: [],
    formattedAvailablePaymentTerms: [],
    subsidyAllowedOnProduct: false,
    showReviewAndSignDocs: false,
    directPayOrder: false,
    vendorOrderTypes: [],
    multipleCountriesEnabled: false,
    enabledCurrencies: [],
    enabledCountries: [],
  });
  const [availableTerms, setAvailableTerms] = useState([]);

  const [searchParams] = useSearchParams();
  const customerNumber = searchParams.get('customerNumber') || '';
  const crmOpportunityId = searchParams.get('crmOpportunityId') || '';
  const crmOpportunityStage = searchParams.get('crmOpportunityStage') || '';
  const crmOpportunityCloseDate = searchParams.get('crmOpportunityCloseDate');
  const crmOpportunityProbability = parseFloat(
    searchParams.get('crmOpportunityProbability')
  );
  const application = searchParams.get('application');

  const sessionData = useReactiveVar(sessionVar);

  const preSelectedProposal = useMemo(() => {
    const totalContractValue = searchParams.get('totalContractValue') || '';
    const spiffRate = searchParams.get('spiffRate') || '';

    return {
      amount: +totalContractValue || '',
      orderType: searchParams.get('paymentType'),
      contractLength: +searchParams.get('term') || null,
      paymentTerm: +searchParams.get('paymentTerm'),
      paymentFrequency: searchParams.get('billingFrequency'),
      subsidy: parseFloat(searchParams.get('subsidy')),
      isDollar: searchParams.get('isDollar') === 'true',
      spiffRate: +spiffRate,
    };
  }, [searchParams]);

  const [directUploadVendorProposalDocs] = useUploadProposalVendorDocs();

  const [getProductConfig, { loading: productConfigLoading }] = useLazyQuery(
    GET_VENDOR_PRODUCT,
    {
      onCompleted: (data) => {
        const product = get(data, 'seller.product', {});
        const {
          isVartanaFinancingChecked,
          isVartanaFinancingCheckBoxDisabled: vartanaFinancingCheckboxState,
        } = checkIsVartanaFinancingAllowed(
          get(product, 'paymentOptions', []),
          get(selectedCustomer, 'creditObject.loanDecision') === 'approved',
          get(selectedCustomer, 'creditObject.expired')
        );
        const { multipleCountriesEnabled, enabledCurrencies, enabledCountries } = get(
          sessionData,
          'session.user.company.product',
          {}
        );
        setIsVartanaFinancingCheckBoxDisabled(vartanaFinancingCheckboxState);
        setDefaultProposalValues((previousValues) => ({
          ...previousValues,
          orderType: get(product, 'defaultOrderType', null),
          paymentTerm: get(product, 'defaultPaymentTerm', null),
          paymentFrequency: get(product, 'defaultPaymentFrequency', null),
          spiffMode: get(product, 'spiffMode'),
          vartanaFinancing: isVartanaFinancingChecked,
        }));

        setInitialFormValues((previousValues) => ({
          ...previousValues,
          proposals: [
            {
              ...defaultProposalValues,
              key: uuidv4(),
              vartanaFinancing: isVartanaFinancingChecked,
            },
          ],
        }));
        setProductConfig((prevConfig) => ({
          ...prevConfig,
          vendorOrderTypes: get(product, 'availableOrderTypes', []),
          multipleCountriesEnabled,
          enabledCurrencies,
          enabledCountries,
        }));
      },
    }
  );

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyId = get(selectedCustomer, 'id', null);
    const companyUrlAdmin = !companyId
      ? ''
      : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const { fullName } = user;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, selectedCustomer, updateIntercom, application]);

  const approvedOffers = useMemo(() => {
    const tempApprovedOffers = get(
      selectedCustomer,
      'creditObject.approvedCreditTermsFormatted',
      []
    );
    const cleanedApprovedOffers = tempApprovedOffers.reduce((prev, current) => {
      return {
        ...prev,
        ...current,
      };
    }, []);
    return tempApprovedOffers.length ? cleanedApprovedOffers : {};
  }, [selectedCustomer]);

  const [createOrder] = useMutation(CREATE_ORDER_WITH_PROPOSALS);
  const [getNewOrderDetails, { loading: CustomerDetailsLoading }] = useLazyQuery(
    GET_NEW_ORDER_DETAILS,
    {
      onCompleted: (data) => {
        const orderFormData = get(data, 'newOrderDetails.orderFormData', {});
        const company = get(data, 'newOrderDetails.company', {});

        setSelectedCustomer(company);
        getProductConfig();

        setProductConfig((prevConfig) => ({
          ...prevConfig,
          availableOrderTypes: get(orderFormData, 'availableOrderTypes', []),
          availablePaymentFrequencies: get(orderFormData, 'frequencies', []),
          deferPaymentTerms: get(orderFormData, 'deferPaymentTerms', []),
          directPaymentTerms: get(orderFormData, 'directPaymentTerms', []),
          installmentPaymentTerms: get(orderFormData, 'installmentPaymentTerms', []),
          subsidyAllowedOnProduct: get(orderFormData, 'showSubsidyField', false),
          showReviewAndSignDocs: get(orderFormData, 'uploadVendorDocs', false),
          defaultOrderType: get(orderFormData, 'defaultOrderType'),
          defaultContractLength: get(orderFormData, 'defaultContractLength'),
          defaultPaymentTerm: get(orderFormData, 'formattedDefaultPaymentTerm.value'),
          defaultPaymentFrequency: get(orderFormData, 'defaultPaymentFrequency'),
          upfrontSalesTaxAndShippingRequired: true,
          spiffMode: get(orderFormData, 'spiffMode'),
          defaultSpiffRate: get(orderFormData, 'defaultSpiffRate') || 0,
          spiffRate: get(orderFormData, 'defaultSpiffRate') || 0,
          currency: get(orderFormData, 'currency'),
          currencySymbol: get(orderFormData, 'currencySymbol'),
          isSyndicated: get(orderFormData, 'isSyndicated', false),
        }));

        setDefaultProposalValues((previousValues) => ({
          ...previousValues,
          orderType: get(orderFormData, 'defaultOrderType'),
          contractLength: get(orderFormData, 'defaultContractLength'),
          paymentTerm: get(orderFormData, 'formattedDefaultPaymentTerm.value', null),
          paymentFrequency: get(orderFormData, 'defaultPaymentFrequency', null),
          ...(isProposalEmpty(preSelectedProposal) ? {} : preSelectedProposal),
        }));

        setInitialFormValues((previousValues) => ({
          ...previousValues,
          proposals: [
            {
              ...defaultProposalValues,
              key: uuidv4(),
              orderType: get(orderFormData, 'defaultOrderType', null),
              paymentTerm: get(orderFormData, 'formattedDefaultPaymentTerm.value', null),
              paymentFrequency: get(orderFormData, 'defaultPaymentFrequency', null),
              ...(isProposalEmpty(preSelectedProposal) ? {} : preSelectedProposal),
            },
          ],
          companyNumber: get(company, 'number', null),
          authorizedSigner: get(company, 'creditObject.pgRequired', false)
            ? {
              email: get(company, 'primaryUser.email', ''),
              firstName: get(company, 'primaryUser.firstName', ''),
              jobTitle: get(company, 'primaryUser.title', ''),
              lastName: get(company, 'primaryUser.lastName', ''),
              phone: get(company, 'primaryUser.formattedPhone', ''),
            }
            : {},
        }));

        setCompanyList([
          {
            id: get(company, 'id', ''),
            name: get(company, 'name', ''),
            number: get(company, 'number', ''),
          },
        ]);
        setIsPageLoading(false);
      },
      onError: () => {
        setIsPageLoading(false);
      },
    }
  );

  useEffect(() => {
    if (selectedCustomer.number && availableTerms.length === 0) {
      fetchAvailableTerms(selectedCustomer.number, setAvailableTerms, setStartDate);
    }
    // adding eslint to stop too many calls fetchAvailableTerms endpoint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTerms.length, selectedCustomer]);

  useEffect(() => {
    setValidationSchema(getOrderFormSchema());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerNumber) {
      setIsPageLoading(true);
      getNewOrderDetails({ variables: { number: customerNumber } });
    }
  }, [customerNumber, getNewOrderDetails]);

  const orderContextValues = useMemo(
    () => ({
      proposalErrors,
      selectedCustomer,
      setProposalErrors,
      availableTermsLoading,
      fetchAvailableTerms,
      startDate,
    }),
    [
      proposalErrors,
      selectedCustomer,
      setProposalErrors,
      availableTermsLoading,
      fetchAvailableTerms,
      startDate,
    ]
  );

  if (!customerNumber && !crmOpportunityId)
    return <Navigate to="/something-went-wrong" replace />;

  const handleSubmit = async (values, { setErrors }) => {
    const proposalsArr = values.proposals?.map((proposal, index) =>
      getProposalPayload(proposal, index, false, getProposalSpiffRate)
    );

    try {
      setIsFormSubmitting(true);
      let variables = {
        companyNumber: selectedCustomer.number,
        message: values.message,
        crmOpportunityId,
        crmOpportunityStage,
        crmOpportunityCloseDate,
        crmOpportunityProbability,
        orderProposals: proposalsArr,
        authorizedSigner: values.authorizedSigner,
      };
      // send reseller details only if reseller mode is enabled and not direct order
      if (resellerMode && !isDirectOrder) {
        variables = {
          ...variables,
          ...values,
        };
      }

      const responseData = await createOrder({ variables });
      const errors = get(responseData, 'errors');
      const authSignerFields = ['email', 'phone'];
      if (errors) {
        setIsFormSubmitting(false);
        if (Object.keys(errors).some((error) => authSignerFields.includes(error)))
          setErrors({ authorizedSigner: errors });
        else {
          showErrorToast();
          reportError(
            `While creating order for customer ${
              selectedCustomer.number
            }: ${JSON.stringify(errors)}`
          );
        }
      } else {
        const { docs, docsMeta } = generateDocsMeta(values.proposals);
        if (docs.length)
          directUploadVendorProposalDocs(docs, docsMeta)
            .then(() => {
              const message = `Upon review, the order will be sent to ${get(
                selectedCustomer,
                'businessName'
              )} for signatures. Visit the "Track application" tab for updates.`;
              showToast('success', message);
              const confirmationPage = getPageUrl({ page: 'confirmation' });
              navigate(confirmationPage, {
                state: {
                  customerName: get(selectedCustomer, 'name', ''),
                  variant: CONFIRMATION_SCREEN_VARIANTS.SIGNATURE_ORDER_REVIEW,
                },
              });
            })
            .catch((error) => {
              showErrorToast();
              console.error(error);
              // Todo: discuss what to do here.
            })
            .finally(() => setIsFormSubmitting(false));
      }
    } catch (error) {
      setIsFormSubmitting(false);
      reportError(error.message);
      showErrorToast();
    }
  };

  const updateProposalErrors = (errors) => {
    setSpiffError(errors);
  };

  return (
    <div className="flex flex-col divide-y gap-6">
      <div className="flex flex-col gap-2 px-14">
        {get(selectedCustomer, 'name', '') ? (
          <Typography variant="heading24" color="color-black-100">
            {titleize(get(selectedCustomer, 'name', ''))}
          </Typography>
        ) : null}
        <Typography variant="paragraph10" bold color="color-black-100">
          Create order
        </Typography>
      </div>

      <div className="flex flex-col">
        <AutoLoad
          loading={isPageLoading || productConfigLoading}
          containerClassName="flex justify-center"
          className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
        >
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const anyProposalHasError = proposalErrors.some(
                (error) => error && !!Object.keys(error).length
              );
              const nextBtnDisabled =
                !formik.isValid ||
                !formik.dirty ||
                anyProposalHasError ||
                spiffError?.spiff;
              const enabledSubmit =
                formik.isValid &&
                formik.dirty &&
                !isEmpty(formik?.values?.authorizedSigner);

              return (
                <Form className="px-10 pt-8 grow flex flex-col items-center w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto">
                  <OrderFormContext.Provider value={orderContextValues}>
                    <div className="flex flex-col gap-6 w-full">
                      <div className="flex flex-col gap-2 w-full">
                        <OrderHeading />
                        <SelectCustomer
                          companies={companyList}
                          name="companyNumber"
                          isDisabled
                        />
                      </div>
                      {currentScreen === orderScreens.proposals && (
                        <div className="py-6 gap-y-6 flex flex-col">
                          <OrderForm
                            selectedCustomer={selectedCustomer}
                            customerDetailsLoading={CustomerDetailsLoading}
                            formik={formik}
                            isVartanaFinancingCheckBoxDisabled={
                              isVartanaFinancingCheckBoxDisabled
                            }
                            productConfig={productConfig}
                            approvedOffers={approvedOffers}
                            defaultProposalValues={defaultProposalValues}
                            updateProposalErrors={updateProposalErrors}
                            availableTermsData={availableTerms}
                          />
                          <div className="flex justify-end">
                            <ButtonV2
                              iconRight="chevron_right"
                              onClick={() => handleNext(formik?.values?.proposals)}
                              text="Next"
                              disabled={nextBtnDisabled}
                              variant={{ type: 'primary', typography: 'paragraph14' }}
                            />
                          </div>
                        </div>
                      )}
                      {currentScreen === orderScreens.reseller && (
                        <ResellerForm
                          formik={formik}
                          vendorName={get(selectedCustomer, 'seller.name', '')}
                          onBack={() => handleBack(formik?.values?.proposals)}
                          onNext={() => handleNext(formik?.values?.proposals)}
                          resellerMode={resellerMode}
                          updateResellerMode={(mode) => toggleResellerMode(mode)}
                        />
                      )}
                      {currentScreen === orderScreens.authorizedSigner && (
                        <>
                          <AuthorizedSigner
                            disableEdit={get(
                              selectedCustomer,
                              'creditObject.pgRequired',
                              false
                            )}
                            customerName={get(selectedCustomer, 'name', '')}
                            phone={formik?.values?.authorizedSigner?.phone || ''}
                          />
                          <div className="flex justify-between">
                            <ButtonV2
                              variant={{ type: 'ghost', typography: 'paragraph14' }}
                              type="button"
                              iconLeft="chevron_left"
                              onClick={() => handleBack(formik?.values?.proposals)}
                              text="Back"
                            />
                            <AutoLoad
                              loading={isFormSubmitting}
                              containerClassName="px-14"
                            >
                              <Button type="submit" disabled={!enabledSubmit}>
                                Send
                              </Button>
                            </AutoLoad>
                          </div>
                        </>
                      )}
                    </div>
                  </OrderFormContext.Provider>
                </Form>
              );
            }}
          </Formik>
        </AutoLoad>
      </div>
    </div>
  );
}

export default Order;
