/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { get, startCase } from 'lodash';

import Table from '../../../../components/Table';
import SortableHeader from '../../../../components/Table/SortableHeader';
import { getStatusClass, formatDate } from '../../../../utils/helpers';
import ZeroState from '../../../../components/ZeroState/ZeroState';

function CustomerInvoices() {
  const { customerData, customerLoading: loading } = useOutletContext();
  const invoices = get(customerData, 'company.invoices', []);

  const columns = useMemo(
    () => [
      {
        Header: (props) => <SortableHeader props={props} title="Invoice #" />,
        accessor: 'number',
        Cell: (props) => (
          <a
            href={props.row.original.url}
            className="break-all vp-text-link-bold  text-vartana-blue-60"
            target="_blank"
            rel="noreferrer external"
          >
            {props.value}
          </a>
        ),
      },
      {
        Header: 'Payments #',
        accessor: 'payments',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.value.map((payment) => (
              <p>{payment.number}</p>
            ))}
          </div>
        ),
      },
      {
        Header: 'Orders #',
        accessor: 'orders',
        Cell: (props) =>
          props.value.map((order) => (
            <div className="vp-body text-vartana-gray-60">
              <Link
                to={{ pathname: `/dashboard/orders/${order.number}/summary` }}
                className="break-all vp-text-link-bold  text-vartana-blue-60"
              >
                {order.number}
              </Link>
            </div>
          )),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Amount" />,
        accessor: 'amount.amount',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.row.original.amount.formattedAmount}
          </div>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Due date" />,
        accessor: 'dueDate.rawDate',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.row.original.dueDate.formattedDate}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>{startCase(props.value)}</span>
        ),
      },
      {
        Header: 'Paid on',
        accessor: 'effectiveDate.formattedDate',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">{props.value}</div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    let formattedInvoiceList = [];
    if (!loading) {
      formattedInvoiceList = invoices.map((inv) => {
        const payments = get(inv, 'payments', []);
        const lastTransaction =
          payments.length > 0 ? payments[payments.length - 1] : null;

        return {
          url: inv.url,
          number: inv.number,
          amount: {
            amount: inv.amount,
            formattedAmount: inv.formattedAmount,
          },
          dueDate: {
            formattedDate: formatDate(inv.dueDate),
            rawDate: Date.parse(inv.dueDate),
          },
          status: inv.status,
          orders: get(inv, 'orders', []),
          payments,
          effectiveDate: {
            formattedDate: lastTransaction?.effectiveDate
              ? formatDate(lastTransaction.effectiveDate)
              : '',
            rawDate: lastTransaction?.effectiveDate
              ? Date.parse(lastTransaction.effectiveDate)
              : '',
          },
        };
      });
    }
    return formattedInvoiceList;
  }, [invoices, loading]);

  const sortBy = useMemo(
    () => [{ id: 'number' }, { id: 'amount.amount' }, { id: 'dueDate.rawDate' }],
    []
  );

  const dataTable = useMemo(() => {
    if (data.length) {
      return (
        <Table
          columns={columns}
          data={data}
          sortBy={sortBy}
          getHeaderProps={() => ({
            className:
              'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
          })}
        />
      );
    }
    return (
      <div className="h-full w-full flex items-center">
        <ZeroState page="customer_invoice" cta="none" />
      </div>
    );
  }, [data, columns, sortBy]);

  return dataTable;
}

export default CustomerInvoices;
