/**
 * A component that displays a label and a corresponding value.
 * If a link is provided, the value will be rendered as a clickable link.
 * If the `emptyField` prop is true, the component will have a different styling to indicate an empty field.
 */

import { Typography } from '@vartanainc/design-system';
import { Link } from 'react-router-dom';

interface LabelValueFieldProps {
  label: string;
  value: string;
  link?: string;
  emptyField?: boolean;
}

const LabelValueField: React.FC<LabelValueFieldProps> = ({
  label,
  value,
  link,
  emptyField,
}) => {
  const getContainerClassName = (): string => {
    const baseClassName = 'flex flex-col flex-1 gap-1';
    return emptyField
      ? `${baseClassName} empty-field`
      : `${baseClassName} populated-field`;
  };

  return (
    <div className={getContainerClassName()}>
      <p className="break-all vartana-p-small-bold text-vartana-gray-50">{label}</p>
      {link ? (
        <Link to={link}>
          <p className="break-all vp-text-link-bold text-vartana-blue-60">{value}</p>
        </Link>
      ) : (
        <Typography
          variant="paragraph14"
          color="color-black-100"
          className="break-all whitespace-pre-line"
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

export default LabelValueField;
