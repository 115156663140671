import React, { useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { difference, get } from 'lodash';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';
import { Typography, Card } from '@vartanainc/design-system';

import { GET_APPROVED_OFFERS } from '../../../../graphql/queries/offers';
import { GET_CUSTOMER_BY_NUMBER } from '../../../../graphql/queries/customer';
import { sessionVar } from '../../../../graphql/cache';
import AutoLoad from '../../../../components/AutoLoad';
import ApprovedPaymentPlans from '../../page_components/ApprovedPaymentPlans';
import { titleize } from '../../../../utils/helpers';
import RequestHigherLimitForm from './form';

export function RequestHigherLimit() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber') || '';
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();
  const navigate = useNavigate();

  useEffect(() => {
    // this redirects to the new page if user tries to access the old page
    const newPagePath = `${location.pathname}${
      location.pathname.endsWith('/') ? 'v2' : '/v2'
    }${location.search}`;
    navigate(newPagePath);
  }, [navigate, location]);

  const { data: offersData, loading: offersLoading } = useQuery(GET_APPROVED_OFFERS, {
    variables: {
      number: companyNumber,
    },
  });

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER_BY_NUMBER,
    {
      variables: {
        number: companyNumber,
      },
    }
  );

  const { availableFrequencies, availablePaymentTerms } = useMemo(() => {
    const allFrequencies = get(
      customerData,
      'company.seller.product.availablePaymentFrequencies',
      []
    );
    const allPaymentTerms = get(
      customerData,
      'company.seller.product.availablePaymentTerms',
      []
    );

    const currentFrequencies = get(
      offersData,
      'company.creditAppraisal.allowedBillingFrequencies',
      []
    );
    const currentPaymentTerms = get(
      offersData,
      'company.creditAppraisal.allowedPaymentTerms',
      []
    );

    return {
      availableFrequencies: difference(allFrequencies, currentFrequencies),
      availablePaymentTerms: difference(allPaymentTerms, currentPaymentTerms),
    };
  }, [customerData, offersData]);

  const offers = useMemo(
    () => get(offersData, 'company.creditAppraisal.approvedCreditTermsFormatted', []),
    [offersData]
  );

  const approvedOffers = useMemo(() => {
    if (offers.length && customerData) {
      return (
        <>
          <div className="text-center text-vartana-black">
            <Typography variant="heading18" bold>
              Approved payment plans
            </Typography>
          </div>
          <ApprovedPaymentPlans
            offers={offers}
            formattedMinAllowedOrderAmount={get(
              customerData,
              'company.formattedBuyerMinAllowedOrderAmount',
              // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
              '$0'
            )}
            pg={get(customerData, 'company.creditAppraisal.pgRequired', false)}
          />
        </>
      );
    }
    return null;
  }, [offers, customerData]);

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyId = get(customerData, 'company.id', null);
    const companyUrlAdmin = !companyId
      ? ''
      : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const { fullName } = user;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, customerData, updateIntercom, application]);

  return (
    <div className="flex flex-col divide-y gap-6">
      <Typography variant="heading32">Request to modify terms</Typography>

      <AutoLoad
        loading={offersLoading || customerLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <div className="py-6 grid grid-cols-2 gap-x-4">
          <Card
            title="Increase higher offer limits"
            variant="variable"
            content={(
              <RequestHigherLimitForm
                companyName={titleize(get(customerData, 'company.name', ''))}
                availableFrequencies={availableFrequencies}
                availablePaymentTerms={availablePaymentTerms}
                conditionalToPg={get(
                  customerData,
                  'company.creditAppraisal.conditionalToPg'
                )}
                creditAssessmentType={get(
                  customerData,
                  'company.creditAppraisal.className',
                  'CreditAppraisal'
                )}
              />
            )}
          >
          </Card>
          <Card
            variant="variable"
            title="Approved payment plans"
            content={(
              <div className="grid grid-flow-row auto-rows-max gap-y-4">
                {approvedOffers}
              </div>
            )}
          >
          </Card>
        </div>
      </AutoLoad>
    </div>
  );
}
