/* eslint-disable object-shorthand */

import React, { useMemo, useEffect } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { get, difference } from 'lodash';

import { useQuery } from '@apollo/client';

import { Typography, Card } from '@vartanainc/design-system';

import {
  GET_CUSTOMER_NAME_BY_NUMBER,
  GET_APPROVED_OFFERS,
} from '../../../graphql/queries/customer';

import RequestHigherOfferLimitsfrom from './new';

import AutoLoad from '../../../components/AutoLoad';
import ApprovedPaymentPlans from '../../CRM/page_components/ApprovedPaymentPlans/index';

export function RequestHigherOfferLimits() {
  const { companyNumber } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // this redirects to the new page if the user tries to access the old page
    navigate(`${pathname}${pathname.endsWith('/') ? 'v2' : '/v2'}`);
  }, [navigate, pathname]);

  const { data: offersData, loading: offersLoading } = useQuery(GET_APPROVED_OFFERS, {
    variables: {
      number: companyNumber,
    },
  });

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER_NAME_BY_NUMBER,
    {
      variables: {
        number: companyNumber,
      },
    }
  );

  const approvedOffers = useMemo(() => {
    const offers = get(
      offersData,
      'company.creditAppraisal.approvedCreditTermsFormatted',
      []
    );
    if (offers.length && customerData) {
      return (
        <ApprovedPaymentPlans
          offers={offers}
          formattedMinAllowedOrderAmount={get(
            customerData,
            'company.formattedBuyerMinAllowedOrderAmount',
            // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
            '$0'
          )}
          pgRequired={get(customerData, 'company.creditAppraisal.pgRequired', false)}
        />
      );
    }
    return null;
  }, [customerData, offersData]);

  const { availableFrequencies, availablePaymentTerms } = useMemo(() => {
    const allFrequencies = get(
      customerData,
      'company.seller.product.availablePaymentFrequencies',
      []
    );
    const allPaymentTerms = get(
      customerData,
      'company.seller.product.availablePaymentTerms',
      []
    );

    const currentFrequencies = get(
      offersData,
      'company.creditAppraisal.allowedBillingFrequencies',
      []
    );
    const currentPaymentTerms = get(
      offersData,
      'company.creditAppraisal.allowedPaymentTerms',
      []
    );

    const indexOfBalloon = allFrequencies.indexOf('balloon', 0);
    if (indexOfBalloon > -1) {
      allFrequencies.splice(indexOfBalloon, 1);
    }
    return {
      availableFrequencies: difference(allFrequencies, currentFrequencies),
      availablePaymentTerms: difference(allPaymentTerms, currentPaymentTerms),
    };
  }, [customerData, offersData]);

  return (
    <div className="flex flex-col">
      <div className="py-8 px-6 border-b border-gray-200">
        <Typography variant="heading32" color="color-black-100">
          Request to modify terms
        </Typography>
      </div>
      <AutoLoad
        loading={offersLoading || customerLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <div className="p-6 bg-gray-50 h-100 grid grid-cols-2 gap-x-6">
          <Card
            title="Increase higher offer limits"
            variant="variable"
            content={(
              <RequestHigherOfferLimitsfrom
                companyName={get(customerData, 'company.name', '')}
                availableFrequencies={availableFrequencies}
                availablePaymentTerms={availablePaymentTerms}
                conditionalToPg={get(
                  offersData,
                  'company.creditAppraisal.conditionalToPg'
                )}
                creditAssessmentType={get(
                  offersData,
                  'company.creditAppraisal.className',
                  'CreditAppraisal'
                )}
              />
            )}
          >
          </Card>

          <Card
            variant="variable"
            title="Approved payment plans"
            content={(
              <div className="grid grid-flow-row auto-rows-max gap-y-4">
                {approvedOffers}
              </div>
            )}
          >
          </Card>
        </div>
      </AutoLoad>
    </div>
  );
}
