import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Card, ButtonV2, Typography } from '@vartanainc/design-system';
import { get, some, startCase, toNumber } from 'lodash';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';

import {
  CURRENT_PAYMENT_OPTIONS,
  CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST_V2,
  GET_CUSTOMER_FOR_MODIFY_REQUEST,
} from '../../graphql/queries/customer';
import { getPageUrl, showErrorToast, showToast } from '../../utils/helpers';

import ModifyTermsForm, { ModifyTermformValueProps } from './ModifyTermsForm';
import { ModifyTermsSummary } from './ModifyTermsSummary';
import CancelConfirmationModal from '../../components/Modals/CancelConfirmation';
import AutoLoad from '../../components/AutoLoad';
import './ModifyTerms.scss';
import { commonRegex, loanDecision } from '../../constants/common.constants';
import SvgIcon from '../../components/SvgIcon/SvgIcon';

interface ModifyTermsProps {
  setLoading: (loading: boolean) => void;
  setCompanyName: (companyName: string) => void;
  onSuccess: () => void;
}

export function ModifyTerms({
  setLoading,
  setCompanyName,
  onSuccess,
}: ModifyTermsProps): ReactElement {
  const { companyNumber: paramCompanyNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isExpandedApp = location.pathname.includes('forms/request-higher-limit');
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = isExpandedApp
    ? useQueryParams.get('customerNumber')
    : paramCompanyNumber;

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const { data: currentPaymentOptions, loading: currentPaymentOptionsLoading } = useQuery(
    CURRENT_PAYMENT_OPTIONS,
    {
      variables: {
        customerNumber: companyNumber,
      },
    }
  );

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER_FOR_MODIFY_REQUEST,
    {
      variables: {
        number: companyNumber,
      },
    }
  );

  const [showCloseFormModal, setShowCloseFormModal] = useState(false);

  const [createCreditAppraisalChangeRequestV2] = useMutation(
    CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST_V2
  );

  const handleSubmit = async (values): Promise<void> => {
    try {
      const inputVars = { ...values, companyNumber };
      const responseData = await createCreditAppraisalChangeRequestV2({
        variables: { ...inputVars },
      });

      const errors = get(responseData, 'errors', {});
      if (Object.keys(errors).length) {
        showErrorToast();
      } else {
        const successMessage =
          'We have received your request and will respond with a decision shortly.';
        if (!isExpandedApp) showToast('success', successMessage);
        onSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validatePageAccess = (): void => {
    // redirect appropraitely if customer is not approved
    const customerLoanDecision = get(
      customerData,
      'company.creditAppraisal.loanDecision'
    );
    const isCustomerNotApproved =
      customerLoanDecision && customerLoanDecision !== loanDecision.approved;

    if (isCustomerNotApproved && !isExpandedApp) {
      const summaryPageUrl = getPageUrl({
        page: 'customerSummary',
        customerNumber: companyNumber || '',
      });
      navigate(summaryPageUrl);
    }
    if (isCustomerNotApproved && isExpandedApp) window.close();
  };

  useEffect(() => {
    validatePageAccess();
    // disabled eslint exhaustive deps because we only want to run this effect on customerData change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  useEffect(() => {
    if (!currentPaymentOptionsLoading && !customerLoading) setLoading(false);
  }, [currentPaymentOptionsLoading, customerLoading, setLoading]);

  useEffect(() => {
    setCompanyName(customerData?.company?.name || '');
  }, [customerData, setCompanyName]);

  const formDropdownOptions = useMemo(() => {
    // this generates dropdown options for form fields based from vendor product
    const productData = customerData?.company?.seller?.product || {};
    const paymentOptions =
      productData.availableOrderTypes?.map((orderType) =>
        orderType === 'installment_plan'
          ? {
              value: 'installment_plan',
              label: 'Installment plan',
            }
          : {
              value: 'full_payment',
              label: 'Defer payment',
            }
      ) || [];
    const paymentTermOptions =
      productData.availablePaymentTerms?.map((term) => ({
        value: term,
        label: term === 0 ? 'Upon receipt' : `Net-${term}`,
      })) || [];
    const billingFrequencyOptions =
      productData.availablePaymentFrequencies?.map((frequency) => ({
        value: frequency,
        label: startCase(frequency),
      })) || [];
    const termOptions =
      productData.availableContractLengths
        ?.map((term) => ({
          value: term,
          label: `${term} months`,
        }))
        ?.filter((term) => term.value !== 0) || [];
    return { paymentOptions, paymentTermOptions, billingFrequencyOptions, termOptions };
  }, [customerData]);

  // set last term option value as the default for dropdown, if not available then set first term option value as a fallback
  const lastIndexOfTermOption = formDropdownOptions.termOptions.length - 1;
  const maxTermOptionValue = get(
    formDropdownOptions,
    `termOptions[${lastIndexOfTermOption}].value`,
    formDropdownOptions?.termOptions[0]?.value
  );

  const formSchema = yup.object().shape({
    totalContractAmount: yup
      .number()
      .nullable()
      .required('Total contract value is required'),
    paymentOption: yup.string().required('Payment option is required'),
    paymentTerm: yup.number().required('Net terms is required'),
    billingFrequency: yup.string().required('Billing frequency is required'),
    term: yup
      .number()
      .required('Contract length is required')
      .test('zero check', 'Contract length should be greater than 0', (value) => {
        if (value) return value > 0;
        return false;
      })
      .test(
        'validity check',
        `Contract length should be less than or equal to ${maxTermOptionValue}`,
        (value) => {
          if (value) return value <= maxTermOptionValue;
          return false;
        }
      ),
    modificationReason: yup.string().required('Modification reason is required'),
  });

  const formikBag = useFormik({
    initialValues: {
      totalContractAmount: null,
      billingFrequency: formDropdownOptions.billingFrequencyOptions[0]?.value,
      paymentOption: formDropdownOptions.paymentOptions[0]?.value,
      term: formDropdownOptions.termOptions[0]?.value,
      paymentTerm: formDropdownOptions.paymentTermOptions[0]?.value,
      modificationReason: '',
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  }) as FormikProps<ModifyTermformValueProps>;

  const onSubmitClick = async (): Promise<void> => {
    formikBag.handleSubmit();
  };

  const onCancelClick = (): void => {
    if (formikBag.dirty) {
      setShowCloseFormModal(true);
    } else {
      navigate(-1);
    }
  };

  const isInstallment = get(formikBag, 'values.paymentOption') === 'installment_plan';

  const getAdditionalAmount = (): string => {
    const totalContractAmount = toNumber(formikBag.values.totalContractAmount);
    // If no TCV, then we don't need to show additional amount
    if (!totalContractAmount) return '';
    const availableAmount = toNumber(
      get(
        currentPaymentOptions,
        `currentPaymentOptions.${
          isInstallment ? 'installmentPlan' : 'deferPayment'
        }.availableAmount`,
        '0'
      ).replace(commonRegex.allNumbers, '')
    );
    // we should subtract from available amount
    const additionalAmount = totalContractAmount - availableAmount;

    // If TCV is less than previously balance, then we don't need to show additional amount as it'll be in negative
    if (additionalAmount < 0) return '';

    return currencyFormat.format(additionalAmount);
  };

  const isSubmitDisabled = (): boolean => {
    return (
      some(formikBag.values, (value) => value === '' || value === undefined) ||
      formikBag.isSubmitting ||
      !formikBag.isValid
    );
  };

  return (
    <AutoLoad
      loading={currentPaymentOptionsLoading || customerLoading}
      containerClassName="absolute top-1/2 left-1/2"
    >
      <CancelConfirmationModal
        open={showCloseFormModal}
        onConfirm={() => navigate(-1)}
        onClose={() => setShowCloseFormModal(false)}
      />

      <div className="modify-term-root-container flex flex-col self-center">
        <div className="flex flex-col gap-[0.625rem] mt-10 mb-8">
          <div className=" flex flex-row gap-2">
            <SvgIcon name="price_change" className="h-8 w-8" fill="color-blue-120" />
            <Typography variant="heading24" color="color-black-100">
              Modify payment options
            </Typography>
          </div>
          <Typography variant="paragraph14" color="color-black-60">
            Please provide order details and specify the reason for updating customer’s
            payment options.
          </Typography>
        </div>
        <div className="h-100">
          <Card
            variant="variable"
            parentContainerClassName="modify-term-card-container"
            content={(
              <div className="flex flex-row gap-10 divide-x">
                <div className="min-w-[36.5rem]">
                  <ModifyTermsForm
                    paymentOptions={formDropdownOptions.paymentOptions}
                    paymentTermOptions={formDropdownOptions.paymentTermOptions}
                    billingFrequencyOptions={formDropdownOptions.billingFrequencyOptions}
                    termOptions={formDropdownOptions.termOptions}
                    formikBag={formikBag}
                  />
                </div>
                <div className="grid grid-flow-row auto-rows-max pl-10 gap-y-4 min-w-[18.5rem]">
                  <ModifyTermsSummary
                    paymentOptions={
                      isInstallment
                        ? currentPaymentOptions?.currentPaymentOptions?.installmentPlan
                        : currentPaymentOptions?.currentPaymentOptions?.deferPayment
                    }
                    requestSummary={{
                      totalContractValue: currencyFormat.format(
                        toNumber(formikBag.values.totalContractAmount)
                      ),
                      additionalAmount: getAdditionalAmount(),
                      paymentOption: isInstallment ? 'Installment plan' : 'Defer payment',
                      contractLength: `${formikBag.values.term} months`,
                      billingFrequency: startCase(formikBag.values.billingFrequency),
                      netTerms:
                        formikBag.values.paymentTerm === 0
                          ? 'Upon receipt'
                          : `Net-${formikBag.values.paymentTerm}`,
                    }}
                    isInstallment={isInstallment}
                    currency={get(customerData, 'company.creditAppraisal.currency', '')}
                  />
                </div>
              </div>
            )}
          />
          <div className="flex flex-row justify-between items-center pt-10 w-full">
            {!isExpandedApp && (
              <div>
                <ButtonV2
                  variant={{
                    type: 'ghost',
                    typography: 'paragraph14',
                  }}
                  type="button"
                  text="Cancel"
                  iconLeft="chevron_left"
                  onClick={onCancelClick}
                />
              </div>
            )}
            <ButtonV2
              className="ml-auto"
              type="button"
              disabled={isSubmitDisabled()}
              text="Submit"
              variant={{
                type: 'primary',
                typography: 'paragraph14',
              }}
              onClick={onSubmitClick}
            />
          </div>
        </div>
      </div>
    </AutoLoad>
  );
}
