import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Typography } from '@vartanainc/design-system';
import ActionRequired from '../../Summary/ActionRequired';
import { PaymentInfoModal } from './PaymentInfoModal';
import { SuccessModal } from './SuccessModal';

// Modal types
const MODAL = {
  success: 'success',
  paymentInfo: 'info',
};

export function PaymentInfoRequired({ orderMeta, onSuccess }) {
  const [currentModal, setCurrentModal] = useState('');
  const orderFinanced = get(orderMeta, 'status', '') === 'financed';

  return (
    <>
      <PaymentInfoModal
        isOpen={currentModal === MODAL.paymentInfo}
        onSuccess={() => setCurrentModal(MODAL.success)}
        onClose={() => setCurrentModal('')}
        orderId={get(orderMeta, 'id', '')}
      />
      <SuccessModal
        isOpen={currentModal === MODAL.success}
        onClose={async () => {
          setCurrentModal('');
          onSuccess();
        }}
      />
      <ActionRequired
        title="Awaiting payout information!"
        message={(
          <div className="flex flex-col gap-4 vp-body text-vartana-black">
            <Typography variant="paragraph12">
              Please provide the following to receive payout.
            </Typography>
            <div className="flex justify-center">
              <p className="text-left">
                &bull; Funding invoice <br />
                &bull; Banking details for payout
              </p>
            </div>
          </div>
        )}
        secondaryMessage={orderFinanced ? null : orderMeta.updatedAt}
        onPrimaryClick={() => setCurrentModal(MODAL.paymentInfo)}
        primaryButtonText="Provide information"
      />
    </>
  );
}

PaymentInfoRequired.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  orderMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
};
