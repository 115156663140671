import Modal from 'react-modal';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ButtonV2, Typography } from '@vartanainc/design-system';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import { CrossButton } from '../Button/CrossButton';
import { isRenderedFromHubspot } from '../../utils/helpers';
import './modal.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

interface MessageProps {
  name: string;
  who: string;
  why: string;
}

interface NeedAdditionalInformationModalProps {
  open?: boolean;
  onClose?: () => void;
  title: string;
  message: MessageProps[];
}

function NeedAdditionalInformationModal({
  open,
  onClose,
  title,
  message,
}: NeedAdditionalInformationModalProps): JSX.Element {
  const modalClassName = isRenderedFromHubspot() ? 'hubspot-need-info-modal' : '';
  return (
    <Modal
      isOpen={open}
      className={twMerge(modalClassName, 'flex justify-center items-center')}
      overlayClassName="bg-vartana-gray-70/80 fixed inset-0 flex justify-center items-center z-10"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className="bg-white w-[37.5rem] rounded-xl relative">
        <CrossButton onClick={onClose} className="top-2 right-2" />
        <div className="flex flex-col p-[2.5rem]">
          <div className="content flex flex-col gap-[1.5rem] overflow-y-auto">
            <div className="flex gap-[0.5rem] items-center">
              <SvgIcon
                name="demography"
                width="2rem"
                height="2rem"
                fill="color-gold-100"
              />
              <Typography color="color-black-100" variant="heading20">
                We need additional information
              </Typography>
            </div>
            <div className="flex flex-col gap-8">
              {(message || []).map((msg) => (
                <div key={msg.name} className="flex flex-col gap-4">
                  <div className="flex flex-row gap-[0.125rem] items-center">
                    <Typography
                      className="inline"
                      color="color-black-100"
                      variant="heading16"
                      bold
                    >
                      {msg.name}
                    </Typography>
                    <div>
                      <TooltipIcon
                        data-tooltip-id={msg.name}
                        data-tooltip-place="top"
                        className="cursor-pointer"
                      />
                      <ReactTooltip
                        id={msg.name}
                        className="max-w-[13.875rem] p-4"
                        opacity={1}
                        // styles prop is used to override the default styles of the tooltip that can't be overridden using ClassName or other props
                        style={{
                          padding: '0.75rem',
                          background: '#333',
                          borderRadius: '0.25rem',
                        }}
                      >
                        <Typography
                          className="inline"
                          color="#fff"
                          variant="paragraph12"
                          bold
                        >
                          Why do we need this?
                        </Typography>
                        <br />
                        <Typography className="block" color="#fff" variant="paragraph12">
                          {msg.why}
                        </Typography>
                      </ReactTooltip>
                    </div>
                  </div>
                  <Typography
                    className="whitespace-pre-line"
                    color="color-black-100"
                    variant="paragraph12"
                  >
                    {msg.who}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="footer flex gap-4 pt-6 justify-end">
            <ButtonV2
              onClick={onClose}
              text="Close"
              variant={{ type: 'primary', typography: 'paragraph14' }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NeedAdditionalInformationModal;
