import { gql } from '@apollo/client';

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $companyNumber: String!
    $contactNumber: String!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $isOwner: Boolean
    $primary: Boolean
    $title: String
    $emailPreferences: EmailPreferencesInput
    $state: String
    $city: String
    $zip: String
    $street: String
    $suite: String
    $country: String
  ) {
    updateContact(
      companyNumber: $companyNumber
      contactNumber: $contactNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      isOwner: $isOwner
      primary: $primary
      title: $title
      emailPreferences: $emailPreferences
      state: $state
      city: $city
      zip: $zip
      street: $street
      suite: $suite
      country: $country
    ) {
      number
      fullName
      primary
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact(
    $companyNumber: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $isOwner: Boolean!
    $emailPreferences: EmailPreferencesInput!
    $state: String
    $city: String
    $country: String
    $zip: String
    $street: String
    $suite: String
  ) {
    createContact(
      companyNumber: $companyNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      isOwner: $isOwner
      emailPreferences: $emailPreferences
      state: $state
      city: $city
      country: $country
      zip: $zip
      street: $street
      suite: $suite
    ) {
      number
      fullName
    }
  }
`;

export const GET_CONTACT_BY_NUMBER = gql`
  query getContactByNumber($number: String!, $companyNumber: String!) {
    user(companyNumber: $companyNumber, number: $number) {
      id
      number
      fullName
      firstName
      lastName
      email
      phone
      formattedPhone
      role
      primary
      createdAt
      emailPreferences
      isOwner
      emailHistory
      attested
      company {
        name
        creditAppraisal {
          conditionalToPg
        }
      }
      defaultAddress {
        state
        city
        zip
        street
        suite
      }
    }
  }
`;
