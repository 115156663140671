import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { humanTimeFormat } from '../../../utils/helpers';
import Loader from '../../../components/Loader';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import FundingInvoice from './FundingInvoice';
import { PaymentInfoRequired } from './PaymentInformation';
import { OrderShowContext } from '../../../context/OrderContext';
import { PaymentInfoContext } from './context';

const GET_PAYMENT_INFO = gql`
  query getPaymentInfo($orderNumber: String!) {
    seller {
      defaultPaymentMethod {
        id
      }
    }
    order(number: $orderNumber) {
      id
      number
      status
      updatedAt
      canUploadFundingInvoice
    }
  }
`;

export function PaymentAndFundingInfoRequired({ orderNumber, stepDetails }) {
  const { refetchOrderData, selectedCustomer } = useContext(OrderShowContext);

  const { data: paymentData, loading: paymentInfoLoading } = useQuery(GET_PAYMENT_INFO, {
    skip: !orderNumber,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    variables: { orderNumber },
    notifyOnNetworkStatusChange: true,
  });

  if (paymentInfoLoading) return <Loader />;
  if (!paymentData?.order?.canUploadFundingInvoice) return null;

  let returnVal = null;
  let updatedAt = get(paymentData, 'order.updatedAt', null);
  if (updatedAt) updatedAt = humanTimeFormat(updatedAt);
  const hasPaymentMethod = !!get(paymentData, 'seller.defaultPaymentMethod.id');
  if (hasPaymentMethod) {
    returnVal = (
      <HasPermission
        resource={RESOURCE.payment}
        action={ACTION.upload_funding_invoice}
        customer={selectedCustomer}
      >
        <FundingInvoice
          orderMeta={{
            id: get(paymentData, 'order.id', ''),
            number: orderNumber,
            status: get(paymentData, 'order.status', 'pending'),
            updatedAt,
          }}
        />
      </HasPermission>
    );
  } else {
    returnVal = (
      <HasPermission
        resource={RESOURCE.payment}
        action={ACTION.upload_funding_invoice}
        customer={selectedCustomer}
      >
        <PaymentInfoRequired
          onSuccess={refetchOrderData}
          orderMeta={{
            id: get(paymentData, 'order.id', ''),
            status: get(paymentData, 'order.status', 'pending'),
            updatedAt,
          }}
        />
      </HasPermission>
    );
  }

  return (
    <PaymentInfoContext.Provider value={{ stepDetails, needFundingInvoice: true }}>
      {returnVal}
    </PaymentInfoContext.Provider>
  );
}

PaymentAndFundingInfoRequired.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  stepDetails: PropTypes.shape({}),
};

PaymentAndFundingInfoRequired.defaultProps = {
  stepDetails: {},
};
