/**
 * CurrencyPill.tsx
 *
 * This exports a pill that can be added to show the currency and flag.
 * It takes the currency as input and detects the flag using the getFlagByCurrency
 * in the helpers function file.
 *
 */

import { upperCase } from 'lodash';
import { Typography } from '@vartanainc/design-system';
import { getFlagByCurrency } from '../../utils/helpers';

interface CurrencyPillProps {
  currency: string;
}

export function CurrencyPill({ currency }: CurrencyPillProps): JSX.Element {
  return (
    <p>
      <span className="items-center gap-1 currencypill bg-vartana-steel-30 rounded-[2rem]">
        <img
          alt="flag"
          src={getFlagByCurrency(currency)}
          className="flex w-[15px] h-[10px] flex-col flag-icon"
        />
        <Typography variant="paragraph10" color="color-steel-180" className="flag-text">
          {upperCase(currency)}
        </Typography>
      </span>
    </p>
  );
}
