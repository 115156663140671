import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Button, ButtonV2, Typography } from '@vartanainc/design-system';
import {
  GET_CUSTOMER_VENDOR_PRODUCT,
  GET_VENDOR_PRODUCT,
} from '../../graphql/queries/product';
import usePermissions from '../../utils/hooks/permissions';
import { CREATE_CUSTOMER, GET_CUSTOMER_NAMES } from '../../graphql/queries/customer';
import {
  CREATE_ORDER_WITH_PROPOSALS,
  GET_NEW_ORDER_DETAILS,
} from '../../graphql/queries/order';
import { ReactComponent as SelectCustomerArrow } from '../../assets/select_customer_arrow.svg';

import AutoLoad from '../../components/AutoLoad';
import SelectCustomer from './selectCustomer';
import { BUYER_TYPE, orderScreens, orderType } from '../../constants/common.constants';
import AuthorizedSigner from './AuthorizedSigner';

import {
  isReseller,
  moveKeys,
  reportError,
  showErrorToast,
  showToast,
} from '../../utils/helpers';
import { useUploadProposalVendorDocs } from '../../utils/hooks';
import {
  checkIsVartanaFinancingAllowed,
  initialOrderFormValues,
  initialProposalObj,
  initialResellerFormValues,
  isProposalEmpty,
  ORDER_CREATION_PLACEHOLDER_EMPTY_TEXT,
  ORDER_CREATION_PLACEHOLDER_FILLED_TEXT,
} from './order.constants';
import { OrderHeading } from './OrderHeading';
import { OrderForm } from './OrderForm';
import { ResellerForm } from '../../macro_components/ResellerForm/ResellerForm';
import { sessionVar } from '../../graphql/cache';
import { GET_RESELLER_AE_OF_CUSTOMER } from '../../graphql/queries/resellers';
import { OrderFormContext } from '../../context/OrderContext';
import { useOrderCreation } from '../../utils/hooks/order_creation';
import { generateDocsMeta, getProposalPayload } from './order.utils';
import TextInputField from '../../designSystem/TextInput/TextInputField';

const CUSTOMER_FORM_KEYS = ['street', 'zip', 'city', 'state', 'country', 'currency'];

/*
  Check if selected customer is directly or indirectly related to current vendor
*/
function isDirect(customer) {
  return customer?.buyerRelationToVendor === BUYER_TYPE.DIRECT;
}

export function OrderCreate() {
  const location = useLocation();
  const navigate = useNavigate();
  const [, hasPermission] = usePermissions();
  const [searchParams] = useSearchParams();
  const customerNumber = searchParams.get('customerNumber') || '';
  const createdCustomerNumber = useRef('');
  const [proposalErrors, setProposalErrors] = useState([]);
  const [directUploadVendorProposalDocs] = useUploadProposalVendorDocs();
  const [debounceId, setDebounceId] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    ...initialOrderFormValues,
    ...initialResellerFormValues,
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [availableTerms, setAvailableTerms] = useState([]);
  const [spiffError, setSpiffError] = useState(null);
  const {
    validationSchema,
    currentScreen,
    resellerMode,
    toggleResellerMode,
    availableTermsLoading,
    fetchAvailableTerms,
    handleNext,
    handleBack,
    getProposalSpiffRate,
    isDirectOrder,
  } = useOrderCreation(selectedCustomer);
  const [companyList, setCompanyList] = useState([]);
  const [isFromCustomerPage, setIsFromCustomerPage] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [newCustomerName, setNewCustomerName] = useState('');
  const [isVartanaFinancingCheckBoxDisabled, setIsVartanaFinancingCheckBoxDisabled] =
    useState(false);
  const [productConfig, setProductConfig] = useState({
    availableOrderTypes: [],
    availablePaymentOptions: [],
    availablePaymentContractLengths: [],
    availablePaymentFrequencies: [],
    formattedAvailablePaymentTerms: [],
    subsidyAllowedOnProduct: false,
    directPayOrder: false,
    showReviewAndSignDocs: false,
    vendorOrderTypes: [],
    multipleCountriesEnabled: false,
    enabledCurrencies: [],
    enabledCountries: [],
  });
  const [defaultProposalValues, setDefaultProposalValues] = useState(initialProposalObj);

  const sessionData = useReactiveVar(sessionVar);
  const loggedInCompany = get(sessionData, 'session.user.company', {});
  const resellerLoggedIn = isReseller(loggedInCompany);

  const approvedOnlyCustomers = useMemo(
    () => !productConfig.availablePaymentOptions?.includes(orderType.full_payment),
    [productConfig.availablePaymentOptions]
  );

  const preSelectedProposal = useMemo(() => {
    const selectedProposal = get(location, 'state.selectedProposal', {});
    const totalContractValue = get(selectedProposal, 'totalContractValue', '') || '';

    return {
      amount: totalContractValue,
      orderType: get(selectedProposal, 'paymentType', null),
      contractLength: get(selectedProposal, 'term', null),
      paymentTerm: get(selectedProposal, 'paymentTerm', null),
      paymentFrequency: get(selectedProposal, 'billingFrequency', null),
      subsidy: get(selectedProposal, 'subsidy', null),
      isDollar: get(selectedProposal, 'isDollar', false),
      spiffRate: get(selectedProposal, 'spiffRate', ''),
    };
  }, [location]);

  const [getCustomers, { loading: customerListLoading }] = useLazyQuery(
    GET_CUSTOMER_NAMES,
    {
      variables: { isResellerUser: resellerLoggedIn },
      onCompleted: (data) => {
        const buyers = get(data, 'buyers.nodes', []).filter(
          (buyer) => isDirect(buyer) || hasPermission('order', 'create', buyer)
        );
        setCompanyList((prevCompanies) => {
          // retain new customers in the list
          const newCustomers = prevCompanies.filter((company) => company?.isNewCustomer);
          return [...newCustomers, ...buyers];
        });
        setIsPageLoading(false);
      },
      onError: () => {
        setIsPageLoading(false);
      },
    }
  );

  const getDefaultCustomers = () => {
    getCustomers(
      approvedOnlyCustomers
        ? {
          variables: {
            first: 10,
            loanDecision: 'approved',
          },
        }
        : {
          variables: {
            first: 10,
          },
        }
    );
  };

  const [createOrder] = useMutation(CREATE_ORDER_WITH_PROPOSALS);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const [getNewOrderDetails, { loading: CustomerDetailsLoading }] = useLazyQuery(
    GET_NEW_ORDER_DETAILS,
    {
      onCompleted: (data) => {
        const orderFormData = get(data, 'newOrderDetails.orderFormData', {});
        const company = get(data, 'newOrderDetails.company', {});

        setProductConfig((prevConfig) => ({
          ...prevConfig,
          availableOrderTypes: get(orderFormData, 'availableOrderTypes', []),
          availablePaymentFrequencies: get(orderFormData, 'frequencies', []),
          deferPaymentTerms: get(orderFormData, 'deferPaymentTerms', []),
          directPaymentTerms: get(orderFormData, 'directPaymentTerms', []),
          installmentPaymentTerms: get(orderFormData, 'installmentPaymentTerms', []),
          subsidyAllowedOnProduct: get(orderFormData, 'showSubsidyField', false),
          showReviewAndSignDocs: get(orderFormData, 'uploadVendorDocs', false),
          defaultOrderType: get(orderFormData, 'defaultOrderType'),
          defaultContractLength: get(orderFormData, 'defaultContractLength'),
          defaultPaymentTerm: get(orderFormData, 'formattedDefaultPaymentTerm.value'),
          defaultPaymentFrequency: get(orderFormData, 'defaultPaymentFrequency'),
          upfrontSalesTaxAndShippingRequired: true,
          spiffMode: get(orderFormData, 'spiffMode'),
          defaultSpiffRate: get(orderFormData, 'defaultSpiffRate') || 0,
          spiffRate: get(orderFormData, 'defaultSpiffRate') || 0,
          currency: get(orderFormData, 'currency'),
          currencySymbol: get(orderFormData, 'currencySymbol'),
          isSyndicated: get(orderFormData, 'isSyndicated', false),
        }));

        setInitialFormValues({
          ...initialFormValues,
          companyNumber: get(company, 'number', ''),
          authorizedSigner: get(company, 'creditObject.pgRequired', false)
            ? {
              email: get(company, 'primaryUser.email', ''),
              firstName: get(company, 'primaryUser.firstName', ''),
              jobTitle: get(company, 'primaryUser.title', ''),
              lastName: get(company, 'primaryUser.lastName', ''),
              phone: get(company, 'primaryUser.formattedPhone', ''),
            }
            : {},
        });
        setSelectedCustomer(company);
        setIsPageLoading(false);
        setProposalErrors([]);
      },
      onError: () => {
        setIsPageLoading(false);
      },
    }
  );

  const [getVendorProduct] = useLazyQuery(GET_VENDOR_PRODUCT, {
    onCompleted: (data) => {
      const product = get(data, 'seller.product', {});
      const availablePaymentOptions = get(product, 'paymentOptions', []);
      const vendorOrderTypes = get(product, 'availableOrderTypes', []);
      const { multipleCountriesEnabled, enabledCurrencies, enabledCountries } = product;
      setProductConfig((prevConfig) => ({
        ...prevConfig,
        availablePaymentOptions,
        vendorOrderTypes,
        multipleCountriesEnabled,
        enabledCurrencies,
        enabledCountries,
      }));
    },
  });

  const [getCustomerVendorProduct] = useLazyQuery(GET_CUSTOMER_VENDOR_PRODUCT, {
    onCompleted: (data) => {
      const product = get(data, 'company.seller.product', {});
      const availablePaymentOptions = get(product, 'paymentOptions', []);
      const { multipleCountriesEnabled, enabledCurrencies, enabledCountries } = product;
      const vendorOrderTypes = get(product, 'availableOrderTypes', []);

      setProductConfig((prevConfig) => ({
        ...prevConfig,
        availablePaymentOptions,
        multipleCountriesEnabled,
        enabledCurrencies,
        enabledCountries,
        vendorOrderTypes,
      }));
    },
  });

  const [getResellerAEOfCustomer] = useLazyQuery(GET_RESELLER_AE_OF_CUSTOMER, {
    variables: {
      resellerNumber: loggedInCompany.number,
    },
  });

  const { isAppraisalApproved, appraisalPaymentTerms, appraisalPaymentFrequencies } =
    useMemo(() => {
      const appraisal = get(selectedCustomer, 'creditObject', {});

      return {
        isAppraisalApproved: appraisal?.loanDecision === 'approved',
        appraisalPaymentTerms: get(appraisal, 'formattedAllowedPaymentTerms', []),
        appraisalPaymentFrequencies: get(appraisal, 'allowedBillingFrequencies', []),
      };
    }, [selectedCustomer]);

  const updateProductByAppraisal = useCallback(() => {
    if (isAppraisalApproved) {
      setProductConfig((prevConfig) => ({
        ...prevConfig,
        formattedAvailablePaymentTerms: appraisalPaymentTerms,
        availablePaymentFrequencies: appraisalPaymentFrequencies,
      }));
    }
  }, [appraisalPaymentFrequencies, appraisalPaymentTerms, isAppraisalApproved]);

  // If vendor logged in, fetch its own product
  // If reseller logged in, fetch product of selected customer's vendor
  useEffect(() => {
    if (selectedCustomer?.number && !isDirect(selectedCustomer)) {
      getCustomerVendorProduct({
        variables: { customerNumber: selectedCustomer.number },
      }).then(() => updateProductByAppraisal());
    } else getVendorProduct().then(() => updateProductByAppraisal());
  }, [
    selectedCustomer,
    getCustomerVendorProduct,
    getVendorProduct,
    updateProductByAppraisal,
  ]);

  const approvedOffers = useMemo(() => {
    const tempApprovedOffers = get(
      selectedCustomer,
      'creditObject.approvedCreditTermsFormatted',
      []
    );
    const cleanedApprovedOffers = tempApprovedOffers.reduce((prev, current) => {
      return {
        ...prev,
        ...current,
      };
    }, []);
    return tempApprovedOffers.length ? cleanedApprovedOffers : {};
  }, [selectedCustomer]);

  const {
    isVartanaFinancingChecked,
    isVartanaFinancingCheckBoxDisabled: vartanaFinancingCheckboxState,
  } = checkIsVartanaFinancingAllowed(
    productConfig.availablePaymentOptions,
    get(selectedCustomer, 'creditObject.loanDecision') === 'approved',
    get(selectedCustomer, 'creditObject.expired')
  );

  useEffect(() => {
    setIsVartanaFinancingCheckBoxDisabled(vartanaFinancingCheckboxState);
    setDefaultProposalValues((previousValues) => ({
      ...previousValues,
      orderType: get(productConfig, 'defaultOrderType', null),
      paymentTerm: get(productConfig, 'defaultPaymentTerm', null),
      paymentFrequency: get(productConfig, 'defaultPaymentFrequency', null),
      vartanaFinancing: isVartanaFinancingChecked,
      ...(isProposalEmpty(preSelectedProposal) ? {} : preSelectedProposal),
    }));
  }, [
    preSelectedProposal,
    selectedCustomer,
    productConfig,
    isVartanaFinancingChecked,
    vartanaFinancingCheckboxState,
  ]);

  useEffect(() => {
    setInitialFormValues((previousValues) => ({
      ...previousValues,
      proposals: [
        {
          ...defaultProposalValues,
          key: uuidv4(),
          orderType: get(productConfig, 'defaultOrderType', null),
          contractLength: get(productConfig, 'defaultContractLength', null),
          paymentTerm: get(productConfig, 'defaultPaymentTerm', null),
          paymentFrequency: get(productConfig, 'defaultPaymentFrequency', null),
          vartanaFinancing: isVartanaFinancingChecked,
          ...(isProposalEmpty(preSelectedProposal) ? {} : preSelectedProposal),
        },
      ],
    }));
  }, [
    defaultProposalValues,
    isVartanaFinancingChecked,
    preSelectedProposal,
    productConfig,
  ]);

  // Pre-select customer from dropdown if available
  useEffect(() => {
    setIsPageLoading(true);
    setIsFromCustomerPage(true);
    if (location?.state?.selectedCompany) {
      const { selectedCompany } = location.state;
      setCompanyList([
        {
          id: selectedCompany.id,
          name: selectedCompany.name,
          number: selectedCompany.number,
        },
      ]);
      getNewOrderDetails({ variables: { number: selectedCompany.number } });
    } else if (customerNumber) {
      getNewOrderDetails({ variables: { number: customerNumber } });
    } else {
      setIsFromCustomerPage(false);
      getCustomers(
        approvedOnlyCustomers
          ? {
            variables: {
              first: 10,
              loanDecision: 'approved',
            },
          }
          : {
            variables: {
              first: 10,
            },
          }
      );
    }
  }, [
    getCustomers,
    location.state,
    approvedOnlyCustomers,
    customerNumber,
    getNewOrderDetails,
  ]);

  // Select customer from dropdown if coming from referrer page
  useEffect(() => {
    if (isFromCustomerPage) {
      setCompanyList([
        {
          id: selectedCustomer?.id || '',
          name: selectedCustomer?.name || '',
          number: selectedCustomer?.number || '',
        },
      ]);
    }
  }, [selectedCustomer, isFromCustomerPage]);

  // Pre-fill reseller fields if reseller customer selected
  useEffect(() => {
    async function preFillFields() {
      if (!selectedCustomer?.number) return;

      if (isDirect(selectedCustomer)) {
        setInitialFormValues((initialVals) => ({
          ...initialVals,
          ...initialResellerFormValues,
        }));
      } else {
        const { data } = await getResellerAEOfCustomer({
          variables: { customerNumber: selectedCustomer?.number || '' },
        });

        const resellerUser = get(data, 'company.resellerAccountExecutive');
        const currentUser = get(sessionData, 'session.user');

        const preFillUser = resellerUser || currentUser;
        const uuid = uuidv4();
        setInitialFormValues((initialVals) => ({
          ...initialVals,
          payoutRecipients: [uuid],
          payoutInformation: `"${loggedInCompany?.name}" will receive a purchase order`,
          isResellerOrder: resellerMode,
          resellers: [
            {
              uuid,
              number: preFillUser?.company?.number || '',
              companyName: preFillUser?.company?.name || '',
              email: preFillUser?.email || '',
              firstName: preFillUser?.firstName || '',
              lastName: preFillUser?.lastName || '',
            },
          ],
        }));
      }
    }
    preFillFields();
  }, [
    getResellerAEOfCustomer,
    loggedInCompany?.name,
    resellerMode,
    selectedCustomer,
    sessionData,
  ]);

  const handleSelectNewCustomer = (customer) => {
    // this function is called when user selects 'Add new customer' option from the dropdown or when new created customer is reselected
    setNewCustomerName(customer);
    setProductConfig((prevConfig) => ({
      vendorOrderTypes: prevConfig.vendorOrderTypes,
      availableOrderTypes: prevConfig.availablePaymentOptions,
      availablePaymentOptions: prevConfig.availablePaymentOptions,
    }));

    const customerExists = companyList.some((company) => company?.number === customer);
    if (!customerExists) {
      setCompanyList((prevList) => [
        ...prevList,
        {
          name: customer,
          number: customer,
          id: uuidv4(),
          isNewCustomer: true,
        },
      ]);
    }

    setInitialFormValues({
      ...initialFormValues,
      companyNumber: customer,
      authorizedSigner: {},
    });
    setSelectedCustomer({
      name: customer,
      businessName: customer,
      number: null,
      isNewCustomer: true,
    });
    getDefaultCustomers();
  };

  const handleSelectedCustomer = (selectedCustomerParam) => {
    if (!selectedCustomerParam.isNewCustomer) {
      getNewOrderDetails({ variables: { number: selectedCustomerParam?.number } });
      getDefaultCustomers();
    } else {
      handleSelectNewCustomer(selectedCustomerParam?.number);
    }
  };

  const handleInputChange = (value) => {
    setProposalErrors([]);
    if (value) {
      clearTimeout(debounceId);
      setDebounceId(
        setTimeout(() => {
          getCustomers({
            variables: {
              name: value?.trim(),
              first: 10,
              loanDecision: approvedOnlyCustomers ? 'approved' : null,
            },
          });
        }, 300)
      );
    }
  };

  const handleCreateCustomer = async (values, setErrors) => {
    let newCustomerNumber = '';
    const [otherValues, customerValues] = moveKeys(values, {}, CUSTOMER_FORM_KEYS);

    // if customer is already created, return other values and customer number
    if (createdCustomerNumber.current)
      return [otherValues, createdCustomerNumber.current];
    try {
      const createCustomerPayload = {
        ...customerValues,
        name: otherValues?.companyNumber,
        country: customerValues?.country || productConfig?.enabledCountries[0],
        currency: customerValues?.currency || productConfig?.enabledCurrencies[0],
      };
      const responseData = await createCustomer({
        variables: createCustomerPayload,
      });
      const errors = get(responseData, 'errors', {});
      if (Object.keys(errors).length) {
        setErrors(errors);
      }
      newCustomerNumber = get(responseData, 'data.createCustomer.number');
      createdCustomerNumber.current = newCustomerNumber;
    } catch (e) {
      console.error(e);
      setFormSubmitting(false);
    }
    return [otherValues, newCustomerNumber];
  };

  const handleSubmit = async (values, { setErrors }) => {
    setFormSubmitting(true);
    let orderValues;
    let companyNumber;

    if (selectedCustomer.isNewCustomer) {
      [orderValues, companyNumber] = await handleCreateCustomer(values, setErrors);
    } else {
      orderValues = values;
      companyNumber = selectedCustomer.number;
    }
    const proposalsArr = values.proposals?.map((proposal, index) =>
      getProposalPayload(proposal, index, false, getProposalSpiffRate)
    );

    try {
      let variables = {
        companyNumber,
        orderProposals: proposalsArr,
        authorizedSigner: values.authorizedSigner,
        message: values.message,
      };
      // send reseller details only if reseller mode is enabled and not direct order
      if (resellerMode && !isDirectOrder) {
        variables = {
          ...variables,
          ...orderValues,
        };
      }

      const responseData = await createOrder({ variables });
      const errors = get(responseData, 'errors');
      const authSignerFields = ['email', 'phone'];
      if (errors) {
        setFormSubmitting(false);
        if (Object.keys(errors).some((error) => authSignerFields.includes(error)))
          setErrors({ authorizedSigner: errors });
        else {
          showErrorToast();
          reportError(
            `While creating order for customer ${
              selectedCustomer.number
            }: ${JSON.stringify(errors)}`
          );
        }
      } else {
        const orderNumber = get(responseData, 'data.createOrderWithProposals.number');
        const { docs, docsMeta } = generateDocsMeta(values.proposals);
        if (docs.length)
          directUploadVendorProposalDocs(docs, docsMeta)
            .then(() => {
              const message = `We have received your order and will send a signature link to your customer. Click on the "Track" tab for updates.`;
              showToast('success', message);
              navigate(`/dashboard/orders/${orderNumber}/summary`);
            })
            .catch(() => {
              showToast('warning', 'Something went wrong while processing proposals');
              // Todo: discuss what to do here.
            })
            .finally(() => {
              setFormSubmitting(false);
            });
      }
    } catch (error) {
      reportError(error.message);
      setFormSubmitting(false);
    }
  };

  useEffect(() => {
    const hasInstallmentSupport = productConfig?.availableOrderTypes?.includes(
      orderType.installments
    );
    // Fetch available terms for selected customer if customer is selected and has installment support
    if (selectedCustomer.number && hasInstallmentSupport) {
      fetchAvailableTerms(selectedCustomer.number, setAvailableTerms, setStartDate);
    }

    // only run this effect when selected customer changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAvailableTerms, selectedCustomer]);

  const updateProposalErrors = (errors) => {
    setSpiffError(errors);
  };

  const showSignatureFlow = useMemo(() => {
    return get(productConfig, 'availablePaymentOptions', []).includes('direct');
  }, [productConfig]);

  const showCustomerDropdown =
    currentScreen === orderScreens.proposals || !selectedCustomer.isNewCustomer;

  const showCustomerAddressForm = selectedCustomer?.isNewCustomer;
  const disableAuthSignerDetails = get(
    selectedCustomer,
    'creditObject.pgRequired',
    false
  );

  const handleSelectCustomerOnBlur = () => {
    if (!customerListLoading) getDefaultCustomers();
  };

  const orderContextValues = useMemo(
    () => ({
      proposalErrors,
      selectedCustomer,
      setProposalErrors,
      availableTermsLoading,
      fetchAvailableTerms,
      startDate,
    }),
    [
      proposalErrors,
      selectedCustomer,
      availableTermsLoading,
      startDate,
      fetchAvailableTerms,
    ]
  );

  return (
    <div className="flex flex-col bg-vartana-steel-20 min-h-screen items-start">
      <div className="px-8 h-[6.375rem] border-b border-gray-200 flex items-center bg-white shrink-0 w-full min-w-[68.75rem]">
        <Typography variant="heading24" color="color-black-100">
          Create Order
        </Typography>
      </div>
      <AutoLoad
        loading={isPageLoading}
        containerClassName="flex justify-center w-full h-full"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <Formik
          initialValues={initialFormValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const anyProposalHasError = proposalErrors.some(
              (error) => error && !!Object.keys(error).length
            );
            const nextBtnDisabled =
              !formik.isValid ||
              !formik.dirty ||
              anyProposalHasError ||
              spiffError?.spiff;
            const enabledSubmit =
              formik.isValid &&
              formik.dirty &&
              !isEmpty(formik?.values?.authorizedSigner);
            const orderProposalData = formik?.values?.proposals;
            return (
              <Form
                id="create-order-form"
                className="px-8 grow flex flex-col items-center w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto"
              >
                <OrderFormContext.Provider value={orderContextValues}>
                  <div
                    className={`${
                      currentScreen === orderScreens.proposals &&
                      formik.values.companyNumber === ''
                        ? 'h-full'
                        : ''
                    } p-10 gap-y-6 flex flex-col w-full`}
                  >
                    <div className="flex flex-col gap-2 w-full ">
                      <header className="flex justify-between">
                        <OrderHeading />
                      </header>
                      {showCustomerDropdown ? (
                        <SelectCustomer
                          companies={companyList}
                          isLoadingCustomers={customerListLoading}
                          handleSelectCustomer={handleSelectedCustomer}
                          handleSelectNewCustomer={handleSelectNewCustomer}
                          onInputChange={handleInputChange}
                          name="companyNumber"
                          isDisabled={
                            currentScreen !== orderScreens.proposals || isFromCustomerPage
                          }
                          isCreateAndSelect={showSignatureFlow}
                          onBlur={handleSelectCustomerOnBlur}
                        />
                      ) : (
                        <div className="customer-name-field-container">
                          <TextInputField
                            name="companyNumber"
                            isDisabled={false}
                            defaultValue={newCustomerName}
                            onInput={(value) => {
                              setNewCustomerName(value.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {currentScreen === orderScreens.proposals &&
                      formik.values.companyNumber !== '' && (
                      <>
                        <OrderForm
                          formik={formik}
                          selectedCustomer={selectedCustomer}
                          customerDetailsLoading={CustomerDetailsLoading}
                          isVartanaFinancingCheckBoxDisabled={
                            isVartanaFinancingCheckBoxDisabled
                          }
                          productConfig={productConfig}
                          approvedOffers={approvedOffers}
                          defaultProposalValues={defaultProposalValues}
                          availableTermsData={availableTerms}
                          updateProposalErrors={updateProposalErrors}
                        />
                        <div className="flex justify-end w-full">
                          <ButtonV2
                            iconRight="chevron_right"
                            onClick={() => handleNext(orderProposalData)}
                            text="Next"
                            disabled={nextBtnDisabled}
                            variant={{ type: 'primary', typography: 'paragraph14' }}
                          />
                        </div>
                      </>
                    )}
                    {currentScreen === orderScreens.proposals &&
                      formik.values.companyNumber === '' && (
                      <div className="flex items-center flex-col mt-36 gap-4 h-full">
                        <SelectCustomerArrow />
                        <Typography variant="heading16" color="color-steel-180">
                          {formik.touched.companyNumber
                            ? ORDER_CREATION_PLACEHOLDER_FILLED_TEXT
                            : ORDER_CREATION_PLACEHOLDER_EMPTY_TEXT}
                        </Typography>
                      </div>
                    )}
                    {currentScreen === orderScreens.reseller && (
                      <ResellerForm
                        formik={formik}
                        onBack={() => handleBack(orderProposalData, setCompanyList)}
                        onNext={() => handleNext(orderProposalData)}
                        resellerMode={resellerMode}
                        updateResellerMode={(mode) => toggleResellerMode(mode)}
                      />
                    )}
                    {currentScreen === orderScreens.authorizedSigner && (
                      <>
                        <AuthorizedSigner
                          disableEdit={disableAuthSignerDetails}
                          customerName={
                            showCustomerAddressForm
                              ? newCustomerName
                              : get(selectedCustomer, 'name', '')
                          }
                          enabledCountries={productConfig?.enabledCountries}
                          phone={formik?.values?.authorizedSigner?.phone || ''}
                          showCustomerAddressForm={showCustomerAddressForm}
                        />
                        <div className="w-full flex justify-between">
                          <ButtonV2
                            variant={{ type: 'ghost', typography: 'paragraph14' }}
                            type="button"
                            iconLeft="chevron_left"
                            onClick={() =>
                              handleBack(
                                orderProposalData,
                                setCompanyList,
                                selectedCustomer?.isNewCustomer
                                  ? selectedCustomer?.name
                                  : null,
                                formik?.values?.companyNumber
                              )}
                            text="Back"
                          />
                          <AutoLoad loading={formSubmitting} containerClassName="px-14">
                            {/** New Button added here need to check why it only shows disabled */}
                            <Button type="submit" disabled={!enabledSubmit}>
                              Submit
                            </Button>
                          </AutoLoad>
                        </div>
                      </>
                    )}
                  </div>
                </OrderFormContext.Provider>
              </Form>
            );
          }}
        </Formik>
      </AutoLoad>
    </div>
  );
}
