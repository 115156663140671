import { TrackStatus } from '@vartanainc/design-system';
import { ReactElement, useEffect } from 'react';

interface TabSummaryProps {
  title: string;
  description: string;
  lastActive: string;
  completed: boolean;
  setActive: () => void;
  step: number;
  progress: number;
  isActive: boolean;
  totalSteps: number;
}

export type trackerState = 'active' | 'active-warn' | 'completed' | 'disabled';
export type TrackerStatus =
  | 'sending-request'
  | 'request-sent'
  | 'need-info'
  | 'in-progress'
  | 'in-review'
  | 'approved'
  | 'finished'
  | 'canceled'
  | 'expired';

interface TrackerStep {
  stepNumber: number;
  stepStatus: trackerState;
}

const ACTIVE_STEP_STATUS = 'active';
const WARN_STEP_STATUS = 'active-warn';
const COMPLETE_STEP_STATUS = 'completed';
const DISABLED_STEP_STATUS = 'disabled';

const OrderStatus = ({
  title,
  description,
  lastActive,
  setActive,
  completed,
  step,
  totalSteps,
  progress,
  isActive,
}: TabSummaryProps): ReactElement => {
  useEffect(() => {
    setActive();
  }, [setActive]);

  const getTrackerSteps = (): TrackerStep[] => {
    const steps: TrackerStep[] = [];
    for (let i = 1; i <= totalSteps; i += 1) {
      steps.push({
        stepNumber: i,
        stepStatus: DISABLED_STEP_STATUS,
      });
    }
    if (completed) {
      steps.forEach((element, index) => {
        const newStep: TrackerStep = element;
        newStep.stepStatus = COMPLETE_STEP_STATUS;
        steps[index] = newStep;
      });
    } else {
      steps.forEach((element, index) => {
        if (element.stepNumber < step) {
          const newStep: TrackerStep = element;
          newStep.stepStatus = COMPLETE_STEP_STATUS;
          steps[index] = newStep;
        }
        if (element.stepNumber === step) {
          const newStep: TrackerStep = element;
          newStep.stepStatus = isActive ? ACTIVE_STEP_STATUS : WARN_STEP_STATUS;
          steps[index] = newStep;
        }
      });
    }
    return steps;
  };

  const getTrackerIcon = (): TrackerStatus => {
    if (step !== null && step !== undefined) {
      return completed ? 'finished' : 'in-progress';
    }
    if (completed) return 'finished';
    switch (title) {
      case 'Order needs approval': {
        return 'need-info';
      }
      case 'Sending order link': {
        return 'sending-request';
      }
      case 'Signatures required': {
        return 'need-info';
      }
      case 'Awaiting payout information': {
        return 'need-info';
      }
      case 'Awaiting invoice': {
        return 'need-info';
      }
      case 'Invoice in review': {
        return 'in-review';
      }
      case 'Agreement in review': {
        return 'in-review';
      }
      case 'Purchase order needs approval': {
        return 'need-info';
      }
      case 'Purchase order in review': {
        return 'in-review';
      }
      case 'Early payoff in review': {
        return 'in-review';
      }
      case 'Reviewing submitted information': {
        return 'in-review';
      }
      case 'Early payoff approved': {
        return 'approved';
      }
      case 'Early payoff completed': {
        return 'finished';
      }
      case 'Order canceled': {
        return 'canceled';
      }
      case 'Order expired': {
        return 'expired';
      }
      default: {
        return 'request-sent';
      }
    }
  };

  return (
    <div className="order-summary w-full flex flex-col items-center gap-4 justify-center bg-slate-100">
      <TrackStatus
        steps={getTrackerSteps()}
        trackerStatus={getTrackerIcon()}
        heading={title}
        description={description}
        timestamp={lastActive}
        isOrder={false}
      />
    </div>
  );
};

export default OrderStatus;
