import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { AlertBox, Typography } from '@vartanainc/design-system';
import { NotifyAlertBox } from '../../../../components/AlertBox';
import CreditAppraisalAndChangeRequestStatusModalV2 from '../../../../components/Modals/CreditAppraisalAndChangeRequestStatusModalV2';
import { ReactComponent as ThumbsUpIcon } from '../../../../assets/thumbsup.svg';
import { appVariants } from '../../../../constants/common.constants';
import { ReactComponent as GrayInfoIcon } from '../../../../assets/gray_info_icon.svg';

function ApprovedPaymentPlans({
  offers,
  pgRequired,
  formattedMinAllowedOrderAmount,
  creditAppraisalChangeRequestStatus,
  variant,
  customerNumber,
}) {
  const [
    showCreditAppraisalAndChangeRequestStatusModal,
    toggleShowCreditAppraisalAndChangeRequestStatusModal,
  ] = useState(false);

  const headers = useMemo(() => {
    return ['Term', 'Max', 'Available'];
  }, []);

  const dataRow = useCallback((term) => {
    const termMonths = Object.keys(term)[0];
    return [
      termMonths === '0' ? 'Pay in full' : `${termMonths} months`,
      term[termMonths].max_range.formatted,
      term[termMonths].remaining.formatted,
    ];
  }, []);

  const handleShowDetailsDialog = () => {
    if (variant === appVariants.vendor) {
      toggleShowCreditAppraisalAndChangeRequestStatusModal(true);
    } else {
      window.open(`/forms/more-details?customerNumber=${customerNumber}`);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center grow space-y-3">
        <table className="table-auto min-w-full">
          <thead>
            <tr>
              {headers.map((title) => (
                <th
                  key={title}
                  className="pt-3 text-center p-xsmall text-vartana-gray-60"
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {offers.map((term) => (
              <tr key={Object.keys(term)[0]}>
                {dataRow(term).map((cell, idx) => (
                  <td
                    key={cell + headers[idx]}
                    className="text-center py-3 border-b vp-p-small"
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Typography variant="paragraph12">
          {formattedMinAllowedOrderAmount} minimum on all terms
        </Typography>

        {get(creditAppraisalChangeRequestStatus, 'change_request_status', false) && (
          <div className="w-full">
            <AlertBox
              variant="alert-box"
              backgroundColor="success"
              icon={<ThumbsUpIcon />}
              content={(
                <Typography variant="paragraph10">
                  {creditAppraisalChangeRequestStatus.message}
                  {['accepted_with_modifications', 'rejected'].includes(
                    get(creditAppraisalChangeRequestStatus, 'change_request_status', '')
                  ) && (
                    <button
                      className="pl-1 cursor-pointer p-xsmall text-vartana-blue-60"
                      onClick={() => handleShowDetailsDialog()}
                    >
                    </button>
                  )}
                </Typography>
              )}
            >
            </AlertBox>
          </div>
        )}

        <div className="w-full">
          <AlertBox
            variant="alert-box"
            backgroundColor="info"
            icon={<GrayInfoIcon />}
            content={(
              <Typography variant="paragraph10">
                Pricing may expire within 30 days from the last approval.
              </Typography>
            )}
          >
          </AlertBox>
        </div>

        {pgRequired && (
          <NotifyAlertBox
            message={(
              <Typography variant="paragraph10">
                A personal guaranty is required for every order.{' '}
              </Typography>
            )}
          />
        )}
      </div>
      {creditAppraisalChangeRequestStatus && (
        <CreditAppraisalAndChangeRequestStatusModalV2
          open={showCreditAppraisalAndChangeRequestStatusModal}
          standardReasons={get(
            creditAppraisalChangeRequestStatus,
            'standard_reasons',
            []
          ).map((reason) => ({ reason, key: uuidv4() }))}
          customReason={get(creditAppraisalChangeRequestStatus, 'custom_reason', '')}
          message={get(creditAppraisalChangeRequestStatus, 'modal_message', '')}
          title={get(creditAppraisalChangeRequestStatus, 'modal_title', '')}
          status={get(creditAppraisalChangeRequestStatus, 'change_request_status', '')}
          onClose={() => toggleShowCreditAppraisalAndChangeRequestStatusModal(false)}
        />
      )}
    </>
  );
}

ApprovedPaymentPlans.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  pgRequired: PropTypes.bool,
  formattedMinAllowedOrderAmount: PropTypes.string,
  creditAppraisalChangeRequestStatus: PropTypes.shape({
    status: PropTypes.string,
    message: PropTypes.string,
    standardReasons: PropTypes.arrayOf(PropTypes.string),
    customReason: PropTypes.string,
  }),
  variant: PropTypes.oneOf(Object.values(appVariants)),
  customerNumber: PropTypes.string,
};

ApprovedPaymentPlans.defaultProps = {
  offers: [],
  pgRequired: false,
  formattedMinAllowedOrderAmount: '',
  creditAppraisalChangeRequestStatus: {},
  variant: appVariants.widget,
  customerNumber: '',
};

export default ApprovedPaymentPlans;
