import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

function ButtonToggle({ name, disabled, className, tooltipElement, label, buttonProps }) {
  const [field, { touched, error }, { setValue, setTouched }] = useField(name);

  const styleClasses = useMemo(
    () => ({
      default: {
        text: 'placeholder-vartana-gray-40',
        label: 'text-vartana-gray-60',
      },
      error: {
        text: 'text-vartana-dark-red placeholder-vartana-dark-red',
        label: 'text-vartana-dark-red',
      },
    }),
    []
  );

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (touched && error) {
      classes = styleClasses.error;
    }
    return classes;
  }, [styleClasses, touched, error]);

  const onSelect = (value) => {
    setTouched(true);
    setValue(value);
  };

  return (
    <>
      <div className={`${className}`}>
        <FormLabel
          containerClassName="mb-2"
          labelClassName={`${formStateClasses.label}`}
          name={field.name}
          label={label}
          tooltipElement={tooltipElement}
        />
        <div className="flex flex-row space-x-4">
          {buttonProps.map((buttonProp) => (
            <div key={buttonProp.value} className="flex-1">
              <button
                type="button"
                disabled={disabled}
                className={`w-full rounded-full focus:outline-none text-center p-3 vp-text-link-bold disabled:cursor-not-allowed ${
                  field.value === buttonProp.value
                    ? 'bg-vartana-blue-40 disabled:bg-vartana-blue-70 text-white'
                    : 'bg-vartana-light-blue disabled:bg-vartana-gray-40-v3 text-vartana-blue-80 disabled:text-vartana-gray-50'
                }`}
                onClick={() => onSelect(buttonProp.value)}
              >
                {buttonProp.label}
              </button>
            </div>
          ))}
        </div>
      </div>
      <FormFieldMsg
        show={touched}
        msg={error}
        className={`${formStateClasses.text} mt-2`}
      />
    </>
  );
}

ButtonToggle.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipElement: PropTypes.node,
  label: PropTypes.string,
  buttonProps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ).isRequired,
};

ButtonToggle.defaultProps = {
  name: '',
  disabled: false,
  className: '',
  tooltipElement: null,
  label: '',
};

export default ButtonToggle;
