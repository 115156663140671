import { useSearchParams } from 'react-router-dom';
import { InviteResellerCard } from '../../components/Cards';

export function ResellerInvite() {
  const [searchParams] = useSearchParams();

  const customerNumber = searchParams.get('customerNumber');
  const customerName = searchParams.get('customerName');

  return (
    <>
      <header className="flex p-8 justify-between items-center border bg-white ">
        <span className="text-gray-900 vp-page-title">Add partner</span>
      </header>

      <div className="w-full p-8 bg-vartana-steel-20 flex justify-center">
        <InviteResellerCard
          customerNumber={customerNumber}
          customerName={customerName}
          app="vendor"
        />
      </div>
    </>
  );
}
