import PropTypes from 'prop-types';
import DangerousTooltip from '../DangerousTooltip';

function AlertBox({ className, prefixIcon, message, postfixTooltip }) {
  return (
    <div className={`flex items-center px-4 py-3 gap-1 w-full ${className}`}>
      {prefixIcon}
      <span className="p-xsmall">{message}</span>
      {postfixTooltip && <DangerousTooltip content={postfixTooltip} />}
    </div>
  );
}

AlertBox.propTypes = {
  className: PropTypes.string.isRequired,
  prefixIcon: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  postfixTooltip: PropTypes.node,
};

AlertBox.defaultProps = {
  prefixIcon: null,
  message: '',
  postfixTooltip: null,
};

export default AlertBox;
