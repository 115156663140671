import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import { ReactComponent as AnnouncementIcon } from '../../assets/announcement_icon.svg';
import AlertBox from './AlertBox';

function NotifyAlertBox({ message }) {
  return (
    <AlertBox
      className="bg-vartana-light-blue-20 text-vartana-blue-160 "
      prefixIcon={<AnnouncementIcon />}
      message={message}
      postfixTooltip={(
        <Typography variant="paragraph10">
          A PG or personal guaranty is an <br />
          agreement confirming that the individual <br />
          who signs is responsible for paying back <br />
          a loan should the business ever becomes <br />
          unable to make payments. The guarantor should <br />
          be an authorized signer with <br />
          ownership or executive responsibilities
        </Typography>
      )}
    />
  );
}

NotifyAlertBox.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotifyAlertBox;
