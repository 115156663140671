/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { useFormik, FormikProvider } from 'formik';

import PropTypes from 'prop-types';

import * as yup from 'yup';
import { toast } from 'react-toastify';

import { useParams, useNavigate } from 'react-router-dom';

import { omit, get, mapKeys, capitalize, isEmpty } from 'lodash';

import { useMutation } from '@apollo/client';

import { useBeforeunload } from 'react-beforeunload';
import { Typography, Button } from '@vartanainc/design-system';

import FormInput from '../../../components/FormInput';
import ButtonToggle from '../../../components/ButtonToggle';
import MultiFileUpload from '../../../components/MultiFileUpload';
import AutoLoad from '../../../components/AutoLoad';
import CustomNotification from '../../../components/CustomNotification';
import MultiSelectButton from '../../../components/MultiSelectButton';
import { useDirectUploadFiles } from '../../../utils/hooks';
import slashLine from '../../../assets/slash_line.svg';
import { CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST } from '../../../graphql/queries/customer';
import {
  requiredPhoneFieldValidation,
  THRESHOLD_AMOUNT,
  THRESHOLD_FORMATTED,
} from '../../../constants/common.constants';
import MoneyInputField from '../../../designSystem/MoneyInput/MoneyInputField';
import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';

const baseSchema = {
  message: yup.string().required('This field is required'),
  increaseThresholdAmount: yup.boolean().oneOf([true, false], 'This field is required'),
  increaseTerm: yup.boolean().oneOf([true, false], 'This field is required'),
  amount: yup
    .number()
    .required('This field is required')
    .positive()
    .nullable()
    .when('increaseThresholdAmount', {
      is: true,
      then: yup
        .number()
        .min(THRESHOLD_AMOUNT + 0.01, `Enter an amount above ${THRESHOLD_FORMATTED}.`),
      otherwise: yup
        .number()
        .max(THRESHOLD_AMOUNT, `Enter an amount upto ${THRESHOLD_FORMATTED}.`),
    }),
  term: yup
    .number()
    .min(12, 'Please input a term between 12 and 60 months.')
    .max(60, 'Please input a term between 12 and 60 months.')
    .integer('Please input an integer.')
    .nullable(),
  billingFrequencies: yup.array().nullable(),
  paymentTerms: yup.array().nullable(),
};

const uploadDocumentsSchema = {
  financialDocuments: yup.array().required('Financial Document is required'),
};

const sendCheckoutLinkSchema = {
  firstName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: requiredPhoneFieldValidation,
  companyMessage: yup.string().required('Please include a message'),
};

function RequestHigherOfferLimits({
  companyName,
  availableFrequencies,
  availablePaymentTerms,
  conditionalToPg,
  creditAssessmentType,
}) {
  const { companyNumber } = useParams();
  const [createCreditAppraisalChangeRequest] = useMutation(
    CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST
  );
  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const [formSchema, setFormSchema] = useState(baseSchema);
  const navigate = useNavigate();
  const [directUploadFiles] = useDirectUploadFiles();
  const [docsRequiredBtn, setDocsRequiredBtn] = useState([]);

  useEffect(() => {
    if (conditionalToPg) {
      setDocsRequiredBtn([{ label: 'Request from customer', value: false }]);
    } else {
      setDocsRequiredBtn([
        { label: 'Upload documents', value: true },
        { label: 'Request from customer', value: false },
      ]);
    }
  }, [conditionalToPg]);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const {
          financialDocuments,
          uploadDocuments,
          increaseThresholdAmount,
          increaseTerm,
          amount,
          term,
          billingFrequencies,
          paymentTerms,
        } = values;

        let cleanedValues = omit(values, [
          'uploadDocuments',
          'financialDocuments',
          'companyName',
        ]);

        if (term) cleanedValues = { ...cleanedValues, term: +term };

        if (!increaseTerm) {
          cleanedValues = omit(cleanedValues, ['term']);
        }

        if (!increaseThresholdAmount || uploadDocuments) {
          cleanedValues = omit(cleanedValues, [
            'firstName',
            'lastName',
            'email',
            'phone',
            'companyMessage',
          ]);
        }

        if (!amount) {
          cleanedValues = {
            ...cleanedValues,
            amount: 0,
          };
        }

        if (!term) {
          cleanedValues = {
            ...cleanedValues,
            term: 0,
          };
        }

        if (!billingFrequencies)
          cleanedValues = omit(cleanedValues, 'billingFrequencies');
        if (!paymentTerms) cleanedValues = omit(cleanedValues, 'paymentTerms');

        const responseData = await createCreditAppraisalChangeRequest({
          variables: { ...cleanedValues },
        });

        const newCreditAppraisalId = get(
          responseData,
          'data.createCreditAppraisalChangeRequest.id',
          ''
        );
        const errors = get(responseData, 'errors', {});
        let successMessage =
          'Your request for terms modification is logged. Upon review, new terms will be generated.';
        const errorsCleaned = mapKeys(errors, (_, key) => {
          if (key === 'creditAppraisalChangeRequestTerm') return 'term';
          if (key === 'creditAppraisalChangeRequestBillingFrequencies')
            return 'billingFrequencies';
          if (key === 'creditAppraisalChangeRequestPaymentTerms') return 'paymentTerms';
          return key;
        });

        if (Object.keys(errorsCleaned).length) {
          setErrors(errorsCleaned);
        } else {
          if (uploadDocuments) {
            await directUploadFiles(
              [...financialDocuments],
              {
                documentType: 'financial',
              },
              {
                id: newCreditAppraisalId,
                type: creditAssessmentType,
              }
            );
          }

          if (increaseThresholdAmount && !uploadDocuments) {
            // send application
            successMessage =
              'New terms will be generated upon receiving financial documents. Visit “Track application” tab for updates.';
          }

          setCanSafelyExit(true);

          setTimeout(() => {
            toast.success(({ toastProps }) => {
              return (
                <CustomNotification type={toastProps.type} message={successMessage} />
              );
            });
            navigate(`/dashboard/customers/${companyNumber}/summary`);
          }, 0);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      navigate,
      companyNumber,
      setCanSafelyExit,
      createCreditAppraisalChangeRequest,
      directUploadFiles,
      creditAssessmentType,
    ]
  );

  const formikBag = useFormik({
    initialValues: {
      companyNumber,
      companyName,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyMessage: '',
      increaseThresholdAmount: null,
      increaseTerm: null,
      billingFrequencies: [],
      paymentTerms: [],
      uploadDocuments: false,
      message: '',
      amount: '',
      term: '',
      financialDocuments: undefined,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit,
  });

  const {
    isSubmitting,
    isValid,
    dirty,
    values: { increaseThresholdAmount, increaseTerm, uploadDocuments },
    touched,
    handleSubmit,
  } = formikBag;

  const disabled = isSubmitting || !(isValid && dirty);

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    let newSchema = { ...baseSchema };

    if (increaseThresholdAmount) {
      if (uploadDocuments) newSchema = Object.assign(newSchema, uploadDocumentsSchema);
      else newSchema = Object.assign(newSchema, sendCheckoutLinkSchema);
    }

    setFormSchema(
      yup.object().shape({
        ...newSchema,
      })
    );
  }, [uploadDocuments, increaseThresholdAmount, setFormSchema]);

  useEffect(() => {
    setCanSafelyExit(!dirty || Object.keys(touched).length === 0);
  }, [touched, dirty]);

  const uploadDocumentsForm = useMemo(() => {
    if (uploadDocuments) {
      return (
        <>
          <MultiFileUpload
            name="financialDocuments"
            label="Financial statements (2 years)"
            acceptMultipleFiles
          />
        </>
      );
    }
    return (
      <>
        <TextInputField name="companyName" label="Company Name" disabled />

        <div className="flex flex-row space-x-4">
          <TextInputField name="firstName" label="Legal first name" />

          <TextInputField name="lastName" label="Legal last name" />
        </div>

        <div className="flex flex-row space-x-4">
          <TextInputField name="email" label="Signer's email" />

          <PatternInput name="phone" label="Phone" mask="_" disableFullStoryRecording />
        </div>

        <div className="flex flex-row">
          <TextAreaInput
            name="companyMessage"
            label="Include a message"
            placeholder="Hello..."
          />
        </div>
      </>
    );
  }, [uploadDocuments]);

  const additionalTermOptions = useMemo(() => {
    if (isEmpty(availableFrequencies) && isEmpty(availablePaymentTerms)) return null;

    const formattedPaymentTerms = availablePaymentTerms.map((term) =>
      term ? [`Net-${term}`, term] : ['Upon receipt', term]
    );

    return (
      <div className="space-y-6 pb-8 mb-2">
        <Typography variant="heading18" bold>
          Additional term options
        </Typography>
        {availableFrequencies.length ? (
          <div>
            <MultiSelectButton
              name="billingFrequencies"
              label="Add frequencies"
              containerClassName="flex flex-row flex-wrap space-x-4 justify-start"
              itemClassName="flex-auto my-2"
              options={availableFrequencies.map((frequency) => ({
                id: frequency,
                label: capitalize(frequency),
                value: frequency,
              }))}
            />
          </div>
        ) : null}
        {availablePaymentTerms.length ? (
          <div>
            <MultiSelectButton
              name="paymentTerms"
              label="Add payment terms"
              containerClassName="flex flex-row flex-wrap space-x-4 justify-start"
              itemClassName="flex-auto my-2"
              options={formattedPaymentTerms.map(([label, value]) => ({
                id: value,
                label,
                value,
              }))}
            />
          </div>
        ) : null}
      </div>
    );
  }, [availableFrequencies, availablePaymentTerms]);

  return (
    <>
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div className="grid grid-cols-1 divide-y divide-vartana-gray-30">
              <div className="pb-8 gap-6">
                <FormInput className="hidden" name="customerNumber" type="hidden" />
                <div className="mb-6">
                  <ButtonToggle
                    name="increaseThresholdAmount"
                    label="Amount needed (across all plans):"
                    buttonProps={[
                      { label: `Under ${THRESHOLD_FORMATTED}`, value: false },
                      { label: `Over ${THRESHOLD_FORMATTED}`, value: true },
                    ]}
                  />
                </div>
                <div className="mb-6">
                  <ButtonToggle
                    name="increaseTerm"
                    label="Maximum term (across all plans):"
                    buttonProps={[
                      { label: 'Within approved options', value: false },
                      { label: 'Beyond approved options', value: true },
                    ]}
                  />
                </div>
                <div className="flex flex-row space-x-4 mb-6">
                  <MoneyInputField
                    id="amount"
                    name="amount"
                    label="Specific amount"
                    placeholder={0}
                    // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
                    prefix="$"
                  />
                  {increaseTerm ? (
                    <>
                      <img className="self-center" alt="slash" src={slashLine} />
                      <TextInputField
                        id="term"
                        name="term"
                        type="number"
                        label="Specific term (optional)"
                      />
                    </>
                  ) : null}
                </div>
                <div className="flex flex-row">
                  <TextAreaInput
                    name="message"
                    label="Supporting details"
                    tooltipElement={(
                      <p>
                        Include specifics about the customer or <br />
                        potential order. For example, “Customer <br />
                        is renewing 40 units and adding 10 units <br />
                        to support future growth”
                      </p>
                    )}
                  />
                </div>

                {increaseThresholdAmount ? (
                  <>
                    <div className="space-y-6 mt-6">
                      <Typography variant="heading18" bold>
                        Documents required
                      </Typography>
                      <div className="space-y-4">
                        <Typography variant="paragraph14" color="color-black-100">
                          Additional documents are needed for requests over{' '}
                          {THRESHOLD_FORMATTED}:
                          <ul className="list-disc ml-8">
                            <li>Last 2 years of financial statements</li>
                          </ul>
                        </Typography>
                      </div>
                    </div>

                    <div className="space-y-6 my-6">
                      <ButtonToggle
                        name="uploadDocuments"
                        label="Select a way to submit documents:"
                        buttonProps={docsRequiredBtn}
                      />
                    </div>
                    <div className="space-y-6">{uploadDocumentsForm}</div>
                  </>
                ) : null}
              </div>
              {additionalTermOptions}
            </div>

            <AutoLoad loading={isSubmitting}>
              <div className="flex flex-row justify-between items-center pt-6">
                <div>
                  <a href={`/dashboard/customers/${companyNumber}/summary`}>
                    <p className="vp-text-link text-vartana-blue-60">Cancel</p>
                  </a>
                </div>
                <div>
                  <Button type="submit" size="regular" disabled={disabled}>
                    Submit
                  </Button>
                </div>
              </div>
            </AutoLoad>
          </fieldset>
        </form>
      </FormikProvider>
    </>
  );
}

RequestHigherOfferLimits.propTypes = {
  companyName: PropTypes.string.isRequired,
  availableFrequencies: PropTypes.arrayOf(PropTypes.string),
  availablePaymentTerms: PropTypes.arrayOf(PropTypes.number),
  conditionalToPg: PropTypes.bool,
  creditAssessmentType: PropTypes.string,
};

RequestHigherOfferLimits.defaultProps = {
  availableFrequencies: [],
  availablePaymentTerms: [],
  conditionalToPg: false,
  creditAssessmentType: 'CreditAppraisal',
};

export default RequestHigherOfferLimits;
