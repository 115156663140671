import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { omit, get } from 'lodash';
import { Typography, Button, Card } from '@vartanainc/design-system';

import { SEND_CHECKOUT } from '../../../../graphql/queries/order';
import { SEND_APPRAISAL_APPLICATION } from '../../../../graphql/queries/customer';

import FormInput from '../../../../components/FormInput';
import { showErrorToast, showToast } from '../../../../utils/helpers';
import { requiredPhoneFieldValidation } from '../../../../constants/common.constants';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import TextAreaInput from '../../../../designSystem/TextAreaInput/TextAreaInput';

const SendCheckoutLinkFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  phone: requiredPhoneFieldValidation,
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  message: yup.string(),
});

function SendCheckoutLinkForm({
  companyNumber,
  crmOpportunityId,
  orderNumber,
  isApplication,
  disabled,
  signer,
  formFocusSwitch,
}) {
  const [initialFocusFlag, setInitialFocusFlag] = useState(false);
  const [sendCheckout, { loading: checkoutSending }] = useMutation(SEND_CHECKOUT);
  const [sendApplicationMutation] = useMutation(SEND_APPRAISAL_APPLICATION);
  const prefilled = Object.keys(signer).length;
  const firstNameRef = useRef();
  const handleFormFocus = () => {
    firstNameRef.current?.focus();
  };

  useEffect(() => {
    if (initialFocusFlag) handleFormFocus();
    else setInitialFocusFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFocusSwitch]);

  const header = useMemo(() => {
    return (
      <div>
        {isApplication ? (
          <>
            <Typography variant="heading18" bold>
              Send application to collect documents
            </Typography>
            <div>
              <Typography variant="paragraph12">
                Tell us who and where to send the application.
              </Typography>
            </div>
          </>
        ) : (
          <Typography variant="heading18" bold>
            Resend or forward checkout link to a new signer
          </Typography>
        )}
      </div>
    );
  }, [isApplication]);

  return (
    <>
      <Card
        variant="fullWidth"
        tabindex={0}
        title={header}
        content={(
          <Formik
            initialValues={{
              validateOnMount: true,
              companyNumber,
              crmOpportunityId,
              number: orderNumber,
              firstName: get(signer, 'firstName', ''),
              lastName: get(signer, 'lastName', ''),
              email: get(signer, 'email', ''),
              phone: get(signer, 'phone', ''),
              message: '',
            }}
            validationSchema={SendCheckoutLinkFormSchema}
            onSubmit={async (values, { setErrors, resetForm }) => {
              try {
                const responseData = isApplication
                  ? await sendApplicationMutation({
                    variables: {
                      ...omit(values, [
                        'validateOnMount',
                        'number',
                        'crmOpportunityId',
                      ]),
                    },
                  })
                  : await sendCheckout({
                    variables: {
                      ...omit(values, ['validateOnMount']),
                    },
                  });

                const errors = get(responseData, 'errors', {});

                if (Object.keys(errors).length) {
                  setErrors(errors);
                } else {
                  let message = 'Checkout link sent!';
                  if (isApplication)
                    message =
                      ' Request sent successfully! Customer will receive document upload link shortly. Contact Vartana helpdesk in case of queries.';
                  showToast('success', message);
                  resetForm();
                }
              } catch (e) {
                showErrorToast();
              }
            }}
          >
            {({ isSubmitting, isValid, dirty, values }) => {
              const submitDisabled =
                disabled || isSubmitting || checkoutSending || !(isValid && dirty);

              return (
                <Form className="mt-4">
                  <fieldset disabled={disabled}>
                    <FormInput className="tw-hidden" name="companyNumber" type="hidden" />
                    <FormInput
                      className="tw-hidden"
                      name="crmOpportunityId"
                      type="hidden"
                    />
                    <FormInput className="tw-hidden" name="number" type="hidden" />
                    <div className="space-y-4">
                      <div className="flex flex-row space-x-4">
                        <TextInputField
                          name="firstName"
                          label="Legal first name"
                          disabled={disabled || prefilled}
                          ref={firstNameRef}
                        />

                        <TextInputField
                          name="lastName"
                          label="Legal last name"
                          disabled={disabled || prefilled}
                        />
                      </div>

                      <div className="flex flex-row space-x-4">
                        <TextInputField
                          name="email"
                          label="Signer's email"
                          disabled={disabled || prefilled}
                        />

                        <PatternInput
                          name="phone"
                          label="Phone"
                          mask="_"
                          value={values.phone}
                          disableFullStoryRecording
                          disabled={disabled || prefilled}
                        />
                      </div>
                      <div className="flex flex-row">
                        <TextAreaInput
                          name="message"
                          label="Include a message"
                          placeholder="Hello..."
                          disabled={disabled}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row justify-end mt-6">
                      <Button type="submit" disabled={submitDisabled}>
                        Send
                      </Button>
                    </div>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        )}
      >
      </Card>
    </>
  );
}

SendCheckoutLinkForm.propTypes = {
  companyNumber: PropTypes.string.isRequired,
  crmOpportunityId: PropTypes.string,
  orderNumber: PropTypes.string,
  disabled: PropTypes.bool,
  isApplication: PropTypes.bool,
  signer: PropTypes.node,
  formFocusSwitch: PropTypes.bool,
};

SendCheckoutLinkForm.defaultProps = {
  crmOpportunityId: '',
  orderNumber: '',
  disabled: false,
  isApplication: false,
  signer: {},
  formFocusSwitch: false,
};

export default SendCheckoutLinkForm;
