import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

import 'react-step-progress-bar/styles.css';

function MultiSelectButton({
  name,
  className,
  containerClassName,
  itemClassName,
  tooltipElement,
  label,
  options,
  disabled,
}) {
  const [field, { touched, error }, { setValue }] = useField({
    name,
    type: 'select',
    multiple: true,
  });

  const styleClasses = useMemo(
    () => ({
      default: {
        text: 'placeholder-vartana-gray-40',
        label: 'text-vartana-gray-60',
      },
      error: {
        text: 'text-vartana-dark-red placeholder-vartana-dark-red',
        label: 'text-vartana-dark-red',
      },
    }),
    []
  );

  const formStateClasses = touched && error ? styleClasses.error : styleClasses.default;

  const onSelect = (newValue) => {
    let list = field.value || [];
    const valueExists = list.includes(newValue);
    if (valueExists) list = list.filter((val) => val !== newValue);
    else list = [...list, newValue];

    setValue(list);
  };

  const isSelected = (value) => {
    const list = field.value || [];
    return list.includes(value);
  };

  return (
    <>
      <div className={`${className}`}>
        <FormLabel
          containerClassName="mb-2"
          labelClassName={`${formStateClasses.label}`}
          name={field.name}
          label={label}
          tooltipElement={tooltipElement}
        />
        <div className={containerClassName}>
          {options.map(({ label: optionLabel, value }) => (
            <div key={value} className={itemClassName}>
              <button
                type="button"
                disabled={disabled}
                className={`
                  w-full rounded-full focus:outline-none p-3 vp-text-link-bold
                  disabled:cursor-not-allowed disabled:bg-vartana-gray-40-v3 disabled:text-vartana-gray-40
                  ${
            isSelected(value)
              ? 'bg-vartana-blue-40 text-white'
              : 'bg-vartana-light-blue text-vartana-blue-80'
            }
                `}
                onClick={() => onSelect(value)}
              >
                <p className="break-all">{optionLabel}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      <FormFieldMsg
        show={touched}
        msg={error}
        className={`${formStateClasses.text} mt-2`}
      />
    </>
  );
}

MultiSelectButton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  tooltipElement: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
};

MultiSelectButton.defaultProps = {
  name: '',
  className: '',
  containerClassName: 'grid grid-cols-3 gap-4',
  itemClassName: '',
  tooltipElement: null,
  disabled: false,
  label: '',
  options: [
    {
      label: '2',
      value: '2',
    },
    {
      label: '1',
      value: '1',
    },
  ],
};

export default MultiSelectButton;
