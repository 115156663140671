/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { get } from 'lodash';
import Table from '../../../../components/Table';
import SortableHeader from '../../../../components/Table/SortableHeader';
import { getStatusClass, formatDate } from '../../../../utils/helpers';
import ZeroState from '../../../../components/ZeroState/ZeroState';

function CustomerPaymentScheduleItems() {
  const { customerData, customerLoading: loading } = useOutletContext();
  const paymentScheduleItems = get(customerData, 'company.paymentScheduleItems', []);

  const columns = useMemo(
    () => [
      {
        Header: (props) => <SortableHeader props={props} title="NEXT DUE DATE" />,
        accessor: 'date.raw',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.row.original.date.formatted}
          </div>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="AMOUNT" />,
        accessor: 'amount.raw',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.row.original.amount.formatted}
          </div>
        ),
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>{props.value}</span>
        ),
      },
      {
        Header: 'INVOICES #',
        accessor: 'invoices',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.value.map((invoice) => (
              <p key={invoice.id}>
                <a
                  href={invoice.url}
                  className="break-all vp-text-link-bold  text-vartana-blue-60"
                  target="_blank"
                  rel="noreferrer"
                >
                  {invoice.number}
                </a>
              </p>
            ))}
          </div>
        ),
      },
      {
        Header: 'PAYMENTS #',
        accessor: 'payments',
        Cell: (props) => (
          <div className="vp-body text-vartana-gray-60">
            {props.value.map((payment) => (
              <p>{payment.number}</p>
            ))}
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    let formattedPaymentScheduleItems = [];
    if (!loading) {
      formattedPaymentScheduleItems = paymentScheduleItems.map((psi) => {
        return {
          date: {
            formatted: formatDate(psi.dueDate),
            raw: Date.parse(psi.dueDate),
          },
          amount: {
            formatted: psi.formattedPayment,
            raw: psi.payment,
          },
          status: psi.status,
          invoices: psi.invoices,
          payments: psi.payments,
        };
      });
    }
    return formattedPaymentScheduleItems;
  }, [paymentScheduleItems, loading]);

  const sortBy = useMemo(() => [{ id: 'date.raw' }, { id: 'amount.raw' }], []);

  const dataTable = useMemo(() => {
    if (data.length) {
      return (
        <Table
          columns={columns}
          data={data}
          sortBy={sortBy}
          getHeaderProps={() => ({
            className:
              'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
          })}
        />
      );
    }
    return (
      <div className="h-full w-full flex items-center">
        <ZeroState page="customer_payment" cta="none" />
      </div>
    );
  }, [data, columns, sortBy]);

  return dataTable;
}

export default CustomerPaymentScheduleItems;
