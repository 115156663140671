// sample test payloads for widgetUtils.tsx, this will be removed once endpoint is implemented

import { ButtonV2, Typography } from '@vartanainc/design-system';
import { useContext } from 'react';
import { get } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { HUBSPOT_QUERY_PARAM, PROGEN_CTA } from '../../../constants/common.constants';
import { WidgetContext } from '../../../context/WidgetContext';
import usePermissions from '../../../utils/hooks/permissions';
import { progenDefaultTexts } from '../../../constants/progenTexts';
import { CalculatorResponseProps, WIDGET_TEXT_CONSTANTS } from './widgetV2.constants';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';

type actionProp = {
  label: string;
  type: string;
  slug: string;
};

export const TertiaryActions = ({
  actions,
  onActionClick,
}: {
  actions: actionProp[];
  onActionClick: (action: actionProp) => void;
}): JSX.Element => {
  const [, hasPermission] = usePermissions();
  const widgetContext = useContext(WidgetContext);
  const selectedCompany = get(widgetContext, 'selectedCompany', {});

  return (
    <div className="flex flex-row gap-2 items-center">
      {actions?.map(({ label, type, slug }, index) => {
        if (
          (slug === PROGEN_CTA.modifyTerms || slug === PROGEN_CTA.cancelRequest) &&
          !hasPermission('order', 'request_term_modification', selectedCompany)
        )
          return <></>;

        return (
          <div key={`${label}-${type}`} className="flex flex-row items-center">
            {index !== 0 && (
              <div className="bg-vartana-gray-50 h-4 w-[0.063rem] mr-2 my-0" />
            )}

            <ButtonV2
              variant={{ type: 'text', typography: 'paragraph12' }}
              text={label.charAt(0).toUpperCase() + label.replace('-', ' ').slice(1)}
              onClick={() => {
                onActionClick({ label, type, slug });
              }}
              style={{ height: 'min-content' }}
            />
          </div>
        );
      })}
    </div>
  );
};

export type actionType = {
  label: string;
  type: string;
  isEnabled: boolean;
  slug: string;
};

export const getPillStatus = (status: string): 'approved' | 'review' | 'info' => {
  switch (status) {
    case 'IN REVIEW':
      return 'review';
    case 'CREDIT REVIEW':
      return 'review';
    case 'APPROVED':
      return 'approved';
    case 'MISSING INFORMATION':
      return 'info';
    case 'NEED INFORMATION':
      return 'info';
    default:
      return 'review';
  }
};

// TODO: remove getActions once endpoint supports ordered buttons
export const getActions = (actions: actionType[]): actionType[] => {
  // this sorts primary and secondary button order
  return actions
    ?.filter((action) => action.type !== 'tertiary')
    .sort((a, b) => b.type.localeCompare(a.type));
};

export const isStatusPage = (status: string): boolean => {
  return (
    status === 'PRICING EXPIRED' ||
    status === 'EXPIRED' ||
    status === 'DECLINED' ||
    status === ''
  );
};

export const handleTrackRequest = (
  isCrm: boolean,
  companyNumber: string,
  crmOpportunityId: string,
  navigate: (path: string) => void
): void => {
  if (isCrm)
    window.open(
      `/forms/summary?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}&tab=summary`
    );
  else navigate(`/dashboard/customers/${companyNumber}/track`);
};

export const renderPgStatus = (
  pgRequired: boolean,
  classNames?: string,
  color = 'color-black-100'
): JSX.Element => {
  if (pgRequired)
    return (
      <div className={twMerge('flex flex-row self-center', classNames)}>
        <Typography variant="paragraph10" color={color} className="mr-[0.125rem]">
          {progenDefaultTexts.pg_description}
        </Typography>
        <div className="relative">
          <SvgIcon
            name="info-filled"
            fill="color-gray-80"
            height="1rem"
            width="1rem"
            className="tooltip-icon"
          />

          <div
            className="tooltip flex p-[0.75rem] jutify-end items-end self-stretch gap-[0.2rem]
           bg-[#333333] rounded shadow-md absolute left-[-2.375rem] bottom-[1.563rem] w-[14.3125rem]"
          >
            <Typography variant="paragraph12" color="color-white-100">
              {progenDefaultTexts.calculator_pg_tooltip}
            </Typography>
          </div>
        </div>
      </div>
    );
  return <></>;
};

// This component renders a tooltip with additional information about calculator fees
export const renderFeeTooltip = (
  response: CalculatorResponseProps | undefined,
  documentationFee: string,
  discountTooltip: string
): JSX.Element => {
  return (
    <div className="flex justify-center items-center relative">
      {/* Tooltip icon */}
      <SvgIcon
        name="info-filled"
        fill="color-gray-80"
        width="1rem"
        height="1rem"
        className="tooltip-icon"
      />
      {/* Tooltip content */}
      <div className="tooltip flex p-3 justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute bottom-[1.7rem] left-[-2.40rem] max-w-[14.3125rem] w-max">
        <Typography
          variant="paragraph12"
          color="color-white-100"
          className="whitespace-pre-line"
        >
          {/* Payout after */}
          {WIDGET_TEXT_CONSTANTS.PAYOUT_AFTER}
          {/* Display documentation fee if available */}
          {get(response, 'vendor_application_fee_tooltip') && (
            <span>
              <br />
              {`- ${documentationFee}`}
            </span>
          )}
          {/* Display discount tooltip if available */}
          {get(response, 'formatted_discount_rate_tooltip') && (
            <span>
              <br />
              {`- ${discountTooltip}`}
            </span>
          )}
        </Typography>
      </div>
    </div>
  );
};

export const handlePageNavigate = (
  url: string,
  isRenderedFromHubspot: boolean,
  navigate: (path: string) => void
): void => {
  if (isRenderedFromHubspot) {
    // check if the url already has the hubspot query param otherwise add it
    const additionalParams = url.includes(HUBSPOT_QUERY_PARAM)
      ? ''
      : `&${HUBSPOT_QUERY_PARAM}`;
    navigate(`${url}${additionalParams}`);
  } else window.open(url);
};
