import { Typography } from '@vartanainc/design-system';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Confirmation } from '../../../../assets/confirmation.svg';

function ConfirmationScreen(): JSX.Element {
  const location = useLocation();
  const { customerName, variant } = location.state;

  const confirmationTextDescription = {
    change_request: 'Hang tight while we review the information.',
    signature_order_direct: 'We have sent the documents to your customer for signature.',
    signature_order_review:
      'We are processing your order and will send the documents to your customer shortly.',
  };

  const confirmationTextTitle = {
    change_request: 'We received your submission.',
    signature_order_direct: 'Thank you.',
    signature_order_review: 'Thank you.',
  };

  return (
    <div className="flex flex-col bg-vartana-steel-20">
      <div className="py-6 px-14 flex flex-col gap-2 border-b border-vartana-gray-40-v3">
        <Typography variant="heading24" color="color-black-100">
          {capitalize(customerName)}
        </Typography>
      </div>
      <div className="w-full mt-[7.5rem] min-h-[calc(100vh-4.875rem)] h-[calc(100vh-4.875rem)] flex items-start justify-center">
        <div className="flex flex-col items-center">
          <Confirmation />
          <Typography variant="heading32" color="color-black-100" className="mt-8">
            {confirmationTextTitle[variant]}
          </Typography>
          <Typography
            variant="paragraph14"
            color="color-black-100"
            className="mt-4 text-center max-w-[354px]"
          >
            {confirmationTextDescription[variant]}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationScreen;
